<template>
    <tr class="client-row">
        <td v-if="!editing" class="d-flex justify-content-between align-items-center w-100">
            <div class="text-left w-100">
                <div>
                    <span class="appointment-discount-label">{{$t('No.')}}:</span>
                    <span>
                        {{index + 1}}
                        <i v-if="newAppointmentDiscount" class="fal fa-star"></i>
                    </span>
                </div>
                <div>
                    <span class="appointment-discount-label">{{$t('Is Percentage?')}}:</span>
                    <span>
                        {{isPercentage ? 'Yes' : 'No'}}
                    </span>
                </div>
                <div>
                    <span class="appointment-discount-label">Amount:</span>
                    <span>
                        {{amountInput}}
                    </span>
                </div>
            </div>
            <div class="text-center">
                <button @click="removeAppointmentDiscount" type="button" class="btn btn-sm btn-secondary">
                    <i class="fal fa-trash text-white"></i>
                </button>
                <button @click="editing=true" type="button" class="btn btn-sm btn-outline-secondary mt-2">
                    <i class="fal fa-pencil"></i>
                </button>
            </div>
        </td>
        <td v-else class="d-flex justify-content-between align-items-center w-100">
            <div class="text-left w-100">
                <div>
                    <span class="appointment-discount-label">{{$t('No.')}}:</span>
                    <span>
                        {{index + 1}}
                        <i v-if="newAppointmentDiscount" class="fal fa-star"></i>
                    </span>
                </div>
                <div class="d-flex align-items-center w-100 mb-1">
                    <span class="appointment-discount-label">{{$t('Is Percentage?')}}:</span>
                    <label class="col ps-toggle-switch">
                        <input v-model="isPercentage" class="toggle-checkbox" type="checkbox">
                        <div class="toggle-switch"></div>
                        <span class="d-inline-block ml-2">{{isPercentage ? 'Yes' : 'No'}}</span>
                    </label>
                </div>
                <div class="d-flex align-items-center w-100 mb-1">
                    <span class="appointment-discount-label">{{$t('Amount')}}:</span>
                    <input v-model="amountInput" class="form-control text-left" type="text" :placeholder="$t('Amount')" @blur="processInput">
                </div>
            </div>
            <div class="text-center">
                <button @click="removeAppointmentDiscount" type="button" class="btn btn-sm btn-outline-secondary">
                    <i class="fal fa-trash"></i>
                </button>
                <button @click="editing=false" type="button" class="btn btn-sm btn-outline-secondary mt-2">
                    <i class="fal fa-arrow-left"></i>
                </button>
            </div>
        </td>
    </tr>
</template>

<script>
    import { mapState } from 'vuex';
    import { AppointmentDiscountItemMixin } from "../../Shared/AppointmentDiscounts/AppointmentDiscountItemMixin";

    export default {
        name: 'AppointmentOverviewDiscountItemMobile',
        mixins: [AppointmentDiscountItemMixin],
        data() {
            return {
                editing: false
            }
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'saveAppointmentEvent'
            ])
        },
        watch: {
            saveAppointmentEvent() {
                this.editing = false
            }
        },
        created() {
            this.editing = !!this.newAppointmentDiscount;
        }
    }
</script>

<style scoped>
    .appointment-discount-label {
        color: #68747a;
        font-weight: 500;
        font-size: 0.875rem;
        margin-right: 0.25rem;
        width: 6rem;
    }
</style>
