<template>
    <tbody>
        <tr :class="classType" v-if="!editing">
            <td class="text-left">
                {{index + 1}}
                <i v-if="newAppointmentDiscount" class="fal fa-star ml-2"></i>
            </td>
            <td>
                {{isPercentage ? 'Yes' : 'No'}}
            </td>
            <td class="text-center">{{amountInput}}</td>
            <td class="d-flex align-items-center justify-content-center">
                <button @click="removeAppointmentDiscount" type="button" class="btn btn-sm btn-secondary">
                    <i class="fal fa-trash text-white"></i>
                </button>
                <button @click="editing=true" type="button" class="btn btn-sm btn-outline-secondary ml-2">
                    <i class="fal fa-pencil"></i>
                </button>
            </td>
        </tr>
        <tr :class="classType" v-else>
            <td class="text-left">
                {{index + 1}}
                <i v-if="newAppointmentDiscount" class="fal fa-star ml-2"></i>
            </td>
            <td>
                <label class="col ps-toggle-switch">
                    <input v-model="isPercentage" class="toggle-checkbox" type="checkbox">
                    <div class="toggle-switch"></div>
                    <span class="d-inline-block ml-2">{{isPercentage ? 'Yes' : 'No'}}</span>
                </label>
            </td>
            <td>
                <input v-model="amountInput" class="form-control text-right" type="text" :placeholder="$t('Amount')" @blur="processInput">
            </td>
            <td class="d-flex align-items-center justify-content-center">
                <button @click="removeAppointmentDiscount" type="button" class="btn btn-sm btn-outline-secondary">
                    <i class="fal fa-trash"></i>
                </button>
                <button @click="editing=false" type="button" class="btn btn-sm btn-outline-secondary ml-2">
                    <i class="fal fa-arrow-left"></i>
                </button>
            </td>
        </tr>
    </tbody>
</template>

<script>
    import { mapState } from 'vuex';
    import { AppointmentDiscountItemMixin } from "../../Shared/AppointmentDiscounts/AppointmentDiscountItemMixin";

    export default {
        name: 'AppointmentDiscountItemDesktop',
        mixins: [AppointmentDiscountItemMixin],
        data() {
            return {
                editing: false
            }
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'saveAppointmentScheduleEvent'
            ]),
            classType() {
                return this.index % 2 > 0 ? 'odd' : 'even';
            }
        },
        watch: {
            saveAppointmentScheduleEvent() {
                this.editing = false
            }
        },
        created() {
            this.editing = !!this.newAppointmentDiscount;
        }
    }
</script>
