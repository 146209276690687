import { Api } from "../../services/Api";

const state = () => ({
    appointmentScheduleAddOnCaches: []
})

const getters = {
    findAppointmentScheduleAddOnCache: (state) => (key) => {
        return state.appointmentScheduleAddOnCaches.find(asoc => asoc.key === key);
    }
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.appointmentScheduleAddOnCaches.find(asoc => asoc.key === key);
        if (!!existing) {
            existing.loading = !!loading;
        } else {
            state.appointmentScheduleAddOnCaches.push({ key: key, loading: !!loading });
        }
    }
}

const actions = {
    addAppointmentScheduleAddOns({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.post('Schedule/AddOn', payload)
                .then(response => {
                    const groupedAddOns = response.data.reduce((ao, addOn) => {
                        const appointmentId = addOn.appointmentId;
                        if (!ao[appointmentId]) {
                            ao[appointmentId] = [];
                        }
                        ao[appointmentId].push(addOn);
                        return ao;
                    }, {});
                    Object.entries(groupedAddOns).forEach(([key, addOns]) => {
                        commit('AddOns/setAddOns', { key, addOns }, { root: true });
                    });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
