import { Api } from "../../services/Api";

const state = () => ({
    addOnCaches: []
})

const getters = {
    findAddOnCache: state => key => {
        return state.addOnCaches.find(aoc => aoc.key === key);
    }
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.addOnCaches.find(aoc => aoc.key === key);
        if (existing) {
            existing.loading = !!loading;
            existing.retry = false;
        } else {
            state.addOnCaches.push({ key: key, values: [], loading: !!loading });
        }
    },
    setAddOns(state, { key, addOns }) {
        const existing = state.addOnCaches.find(aoc => aoc.key === key);
        if (existing) {
            const index = state.addOnCaches.indexOf(existing);
            state.addOnCaches.splice(index, 1, { key: key, values: addOns, loading: false });
        } else {
            state.addOnCaches.push({ key: key, values: addOns, loading: false });
        }
    },
    setRetry(state, key) {
        const existing = state.addOnCaches.find(aoc => aoc.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    }
}

const actions = {
    findAddOns({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.post('AddOn/Search', payload)
                .then(response => {
                    commit('setAddOns', { key: key, addOns: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', key);
                    reject(error);
                });
        });
    },
    uploadAddOn({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.put('AddOn', payload)
                .then(response => {
                    commit('setAddOns', { key: key, addOns: response.data });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    },
    uploadScheduleAddOns({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.put('Schedule/AddOn', payload)
                .then(response => {
                    const groupedAddOns = response.data.reduce((ao, addOn) => {
                        const appointmentId = addOn.appointmentId;
                        if (!ao[appointmentId]) {
                            ao[appointmentId] = [];
                        }
                        ao[appointmentId].push(addOn);
                        return ao;
                    }, {});
                    Object.entries(groupedAddOns).forEach(([key, addOns]) => {
                        commit('setAddOns', { key, addOns });
                    });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
