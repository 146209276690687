import { Api } from '../../services/Api';

const state = () => ({
    fetchedJobScheduler: false,
    loadingJobScheduler: false,
    jobScheduler: null
})

const mutations = {
    setLoading(state, fetching) {
        state.loadingJobScheduler = !!fetching;
        state.fetchedJobScheduler = false;
    },
    setJobScheduler(state, jobScheduler) {
        state.jobScheduler = jobScheduler;
        state.fetchedJobScheduler = true;
        state.loadingJobScheduler = false;
    }
}

const actions = {
    fetchJobScheduler({ commit }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', true);
            Api.get('JobScheduler')
                .then(response => {
                    commit('setJobScheduler', response.data);
                    resolve(response);
                })
                .catch(error => {
                    commit('setLoading', false);
                    reject(error);
                })
        });
    },
    updateJobScheduler({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit('setLoading', true);
            Api.put('JobScheduler', payload)
                .then(response => {
                    commit('setJobScheduler', response.data);
                    resolve(response);
                })
                .catch(error => {
                    commit('setLoading', false);
                    reject(error);
                })
        });
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
