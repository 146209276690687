<template>
    <div>
        <password-input v-model="password" :placeholder="$t('Choose a password')"></password-input>
        <validator-messages v-model="valid" :password="password"></validator-messages>
    </div>
</template>

<script>
    import PasswordInput from './PasswordInput.vue';
    import PasswordValidatorMessages from './PasswordValidatorMessages.vue'

    export default {
        name: 'PasswordValidator',
        props: {
            value: String
        },
        data() {
            return {
                password: this.value,
                valid: false
            }
        },
        watch: {
            password() {
                this.$emit('input', this.valid ? this.password : '');
            },
            valid() {
                this.$emit('input', this.valid ? this.password : '');
            }
        },
        components: {
            'password-input': PasswordInput,
            'validator-messages': PasswordValidatorMessages
        }
    }
</script>
