import { mapActions } from 'vuex';
import { AppointmentCacheMixin } from "./AppointmentCacheMixin";
import { AddOnUploadMixin } from '../AddOns/AddOnUploadMixin';
import { ErrorHelper } from '../../../helpers/ErrorHelper';

export const AppointmentScheduleAddOnsUploadMixin = {
    mixins: [AppointmentCacheMixin, AddOnUploadMixin],
    data() {
        return {
            updateTypeId: 0,
            previousUpdateTypeId: null
        }
    },
    computed: {
        scheduleId() {
            return !!this.appointment ? this.appointment.scheduleId : '';
        },
        petId() {
            return !!this.appointment ? this.appointment.petId : '';
        },
        sequenceNumber() {
            return !!this.appointment ? this.appointment.sequenceNumber : 0;
        },
        scheduleAddOnUploadModel() {
            return {
                scheduleId: this.scheduleId,
                sequenceNumber: this.sequenceNumber,
                petId: this.petId,
                addOns: [...this.addOns]
            }
        },
        scheduleAddOnUploadModelValid() {
            const model = this.scheduleAddOnUploadModel;
            return !!model.scheduleId && !!model.petId && this.addOnValidator.isValid;
        },
        scheduleAddOnUploadModelModified() {
            return this.updateTypeId !== this.previousUpdateTypeId || this.addOnUploadModelModified;
        }
    },
    watch: {
        addOnItems() {
            this.refreshAddOnItems();
        },
        appointmentId() {
            this.refreshAddOnItems();
        }
    },
    methods: {
        ...mapActions('AddOns', [
            'uploadScheduleAddOns'
        ]),
        refreshAddOnItems() {
            this.addOns = [...this.addOnItems]
        },
        tryUploadAddOns() {
            if (this.updateTypeId === 2) {
                this.tryUploadScheduleAddOns();
            }
            else {
                this.tryUploadAddOn();
            }
        },
        tryUploadScheduleAddOns() {
            const self = this;
            self.addOnValidator.check(self.scheduleAddOnUploadModel);
            if (!self.scheduleAddOnUploadModelValid || !self.scheduleAddOnUploadModelModified) {
                return;
            }
            self.error = '';
            self.uploadScheduleAddOns({ key: self.appointmentId, payload: self.scheduleAddOnUploadModel })
                .then(() => self.previousUpdateTypeId = self.updateTypeId)
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshAddOnItems();
    }
}
