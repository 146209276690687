<template>
    <tr class="client-row">
        <td v-if="!editing" class="d-flex justify-content-between align-items-center w-100">
            <div class="text-left w-100">
                <div>
                    <span class="add-on-label">No.:</span>
                    <span>
                        {{index + 1}}
                        <i v-if="newAddOn" class="fal fa-star"></i>
                    </span>
                </div>
                <div>
                    <span class="add-on-label">{{$t('Name')}}:</span>
                    <span>
                        {{name}}
                    </span>
                </div>
                <div>
                    <span class="add-on-label">{{$t('Quantity')}}:</span>
                    <span>
                        {{quantity}}
                    </span>
                </div>
                <div>
                    <span class="add-on-label">{{$t('Amount')}}:</span>
                    <span>
                        {{currencySymbol}}{{amountInput}}
                    </span>
                </div>
                <div>
                    <span class="add-on-label">{{$t('Tax')}}:</span>
                    <span>
                        {{payableAddOnTaxString}}
                    </span>
                </div>
            </div>
            <div class="text-center">
                <button @click="removeAddOn" type="button" class="btn btn-sm btn-secondary">
                    <i class="fal fa-trash text-white"></i>
                </button>
                <button @click="editing=true" type="button" class="btn btn-sm btn-outline-secondary mt-2">
                    <i class="fal fa-pencil"></i>
                </button>
            </div>
        </td>
        <td v-else class="d-flex justify-content-between align-items-center w-100">
            <div class="text-left w-100">
                <div>
                    <span class="add-on-label">{{$t('No.')}}:</span>
                    <span>
                        {{index + 1}}
                        <i v-if="newAddOn" class="fal fa-star"></i>
                    </span>
                </div>
                <div class="d-flex align-items-center w-100 mb-1">
                    <span class="add-on-label">{{$t('Name')}}:</span>
                    <input v-model="name" class="form-control" :placeholder="$t('Name')">
                </div>
                <div class="d-flex align-items-center w-100 mb-1">
                    <span class="add-on-label">{{$t('Quantity')}}:</span>
                    <input v-model="quantity" class="form-control" :placeholder="$t('Quantity')">
                </div>
                <div class="d-flex align-items-center w-100 mb-1">
                    <span class="add-on-label">{{$t('Amount')}}:</span>
                    <input v-model="amountInput" class="form-control text-left" type="text" :placeholder="$t('Price')" @blur="processInput">
                </div>
                <div>
                    <span class="add-on-label">{{$t('Tax')}}:</span>
                    <span>
                        {{payableAddOnTaxString}}
                    </span>
                </div>
            </div>
            <div class="text-center">
                <button @click="removeAddOn" type="button" class="btn btn-sm btn-outline-secondary">
                    <i class="fal fa-trash"></i>
                </button>
                <button @click="handleBack" type="button" class="btn btn-sm btn-outline-secondary mt-2">
                    <i class="fal fa-arrow-left"></i>
                </button>
            </div>
        </td>
    </tr>
</template>

<script>
    import { mapState } from 'vuex';
    import { AddOnItemMixin } from "../../Shared/AddOns/AddOnItemMixin";

    export default {
        name: 'AppointmentAddOnMobile',
        mixins: [AddOnItemMixin],
        data() {
            return {
                editing: false
            }
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'saveAppointmentScheduleEvent'
            ]),
        },
        watch: {
            saveAppointmentScheduleEvent() {
                this.editing = false
            }
        },
        created() {
            this.editing = !!this.newAddOn;
        }
    }
</script>

<style scoped>
    .add-on-label {
        color: #68747a;
        font-weight: 500;
        font-size: 0.875rem;
        margin-right: 0.25rem;
        width: 6rem;
    }
</style>