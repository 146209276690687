<template>
    <tr>
        <td>
            <div class="client-row">
                <div class="client-row-details">
                    <div class="crd-name">
                        {{$t(name)}} {{$t('Report')}}: {{$t(status)}}
                    </div>
                    <div class="crd-contact">
                        {{$t('Created')}}: {{created}}
                    </div>
                    <div v-if="completed" class="crd-contact">
                        <button v-if="completed" @click="downloadReport" class="btn btn-sm btn-outline-secondary" title="Download">
                            <i class="fa-regular fa-file-arrow-down"></i>
                        </button>
                        {{$t('Expires')}}: {{expires}}
                    </div>
                    <template v-else>
                        <div class="crd-contact">
                            {{message}}
                        </div>
                    </template>
                </div>
            </div>
        </td>
    </tr>
</template>

<script>
    import { ReportItemMixin } from "./ReportItemMixin";

    export default {
        name: 'ReportItemMobile',
        mixins: [ReportItemMixin]
    }
</script>
