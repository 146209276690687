<template>
    <div class="tag-item pointer" v-bind:style="computedStyles" @click="onClick">
        <div v-if="isActive">
            <slot name="active"></slot>
        </div>
        <div v-else class="tag-inactive">
            <slot name="inactive" class="inactive"></slot>
        </div>
        <div class="tag-text">{{ tagTypeName }}</div>
    </div>
</template>

<script>
    export default {
        name: 'TagItem',
        props: {
            tagType: Object,
            tags: Array,
            isActive: false
        },
        computed: {
            tagColour() {
                return this.tagType?.colour ?? '#1CA085';
            },
            tagTypeName() {
                return this.tagType?.name ?? ''
            },
            computedStyles() {
                return this.isActive
                    ? {
                        backgroundColor: this.tagColour,
                        color: "#FFF",
                        border: `1.5px solid ${this.tagColour}`
                    }
                    : {
                        color: this.tagColour
                    };
            }
        },
        methods: {
            onClick() {
                this.$emit('toggled', this.tagType);
            }
        }
    }
</script>
