import { ProductInputMixin } from "../../Products/ProductInputMixin";
import { AppointmentTotalMixin } from "../../Shared/AppointmentTotalMixin";

export const AddOnItemMixin = {
    mixins: [AppointmentTotalMixin, ProductInputMixin],
    props: {
        value: Array,
        index: Number
    },
    computed: {
        addOn: {
            get() {
                return this.value[this.index];
            },
            set(value) {
                const addOns = [...this.value];
                addOns.splice(this.index, 1, value);
                this.$emit('input', addOns);
            }
        },
        orderItems() {
            return []
        },
        addOns() {
            return [this.addOn];
        },
        quantity: {
            get() {
                return this.addOn.quantity;
            },
            set(value) {
                this.addOn = Object.assign({}, this.addOn, {
                    quantity: Math.max(1, parseInt(value))
                });
            }
        },
        name: {
            get() {
                return this.addOn.name;
            },
            set(value) {
                this.addOn = Object.assign({}, this.addOn, {
                    name: value
                });
            }
        },
        newAddOn() {
            return !!this.addOn?.newAddOn ? true : false;
        }
    },
    watch: {
        addOn() {
            this.setInitial();
        },
        amount(value) {
            this.addOn = Object.assign({}, this.addOn, {
                amount: value
            });
        }
    },
    methods: {
        setInitial() {
            this.setInitialAmount(parseFloat(this.addOn.amount));
        },
        removeAddOn() {
            this.$emit('removeAddOn', { index: this.index, addOn: this.addOn });
        },
        handleBack() {
            this.editing = false;
            this.name = this.name || 'Default';
        }
    },
    created() {
        this.setInitial();
    }
}
