import { mapState, mapActions } from 'vuex';
import { ErrorHelper } from '../../helpers/ErrorHelper';

export const AppointmentTotalSettingsCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('AppointmentTotalSettings', [
            'fetchedAppointmentTotalSettings',
            'fetchingAppointmentTotalSettings',
            'appointmentTotalSettings'
        ]),
        maxAddOnsLimit() {
            return this.appointmentTotalSettings ? this.appointmentTotalSettings.maxAddOns : 10;
        },
        maxDiscountsLimit() {
            return this.appointmentTotalSettings ? this.appointmentTotalSettings.maxDiscounts : 10;
        }
    },
    methods: {
        ...mapActions('AppointmentTotalSettings', [
            'fetchAppointmentTotalSettings'
        ]),
        refreshAppointmentTotalSettings() {
            if (!this.fetchedAppointmentTotalSettings && !this.fetchingAppointmentTotalSettings) {
                this.tryFetchAppointmentTotalSettings();
            }
        },
        tryFetchAppointmentTotalSettings() {
            const self = this;
            self.error = '';
            self.fetchAppointmentTotalSettings()
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshAppointmentTotalSettings();
    }
}
