import { mapActions } from 'vuex';
import { ErrorHelper } from "../../../helpers/ErrorHelper";
import { AppointmentDiscountHelper } from "../../../helpers/AppointmentDiscountHelper";
import { AppointmentDiscountCacheMixin } from "./AppointmentDiscountCacheMixin";

export const AppointmentDiscountUploadMixin = {
    mixins: [AppointmentDiscountCacheMixin],
    data() {
        return {
            appointmentDiscounts: [],
            appointmentDiscountValidator: AppointmentDiscountHelper.getAppointmentDiscountValidator()
        }
    },
    computed: {
        appointmentDiscountUploadModel() {
            return {
                appointmentId: this.appointmentId,
                appointmentDiscounts: [...this.appointmentDiscounts]
            }
        },
        appointmentDiscountUploadModelValid() {
            const model = this.appointmentDiscountUploadModel;
            return !!model.appointmentId && this.appointmentDiscountValidator.isValid;
        },
        appointmentDiscountUploadModelModified() {
            return JSON.stringify(this.appointmentDiscountUploadModel) !== JSON.stringify({
                appointmentId: this.appointmentId,
                appointmentDiscounts: [...this.appointmentDiscountItems]
            });
        }
    },
    methods: {
        ...mapActions('AppointmentDiscounts', [
            'uploadAppointmentDiscount'
        ]),
        tryUploadAppointmentDiscount() {
            const self = this;
            self.appointmentDiscountValidator.check(self.appointmentDiscountUploadModel);
            if (!self.appointmentDiscountUploadModelValid || !self.appointmentDiscountUploadModelModified) {
                return;
            }
            self.error = '';
            self.uploadAppointmentDiscount({ key: self.appointmentId, payload: self.appointmentDiscountUploadModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
