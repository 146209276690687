import vueCookies from 'vue-cookies';
import { EmailHelper } from './EmailHelper';

export const SiteHelper = {
    getDefaultSite() {
        const site = vueCookies.get('selected_site');
        return !!site ? site : {
            id: '',
            name: 'NO SITE SELECTED'
        }
    },
    setDefaultSite(site) {
        vueCookies.set('selected_site', site, -1, null, null, true, 'Strict');
    },
    clearCookie() {
        vueCookies.remove('selected_site');
    },
    getSiteValidator() {
        return {
            isValid: false,
            companyName: '',
            name: '',
            email: '',
            check(model) {
                this.companyName = !!model.companyName ? '' : 'Please enter company name';
                this.name = !!model.name ? '' : 'Please enter a site name';
                this.email = !!model.email ? EmailHelper.isValid(model.email) ? '' : 'Please enter a valid email' : 'Please enter an email';
                this.isValid = !this.companyName && !this.name && !this.email;
            }
        }
    },
    getDefaultCalendarSettings() {
        return {
            startHour: '08:00',
            endHour: '17:00',
            startDay: 1,
            hiddenDays: [0],
            nonBusinessHoursAmount: 2
        }
    }
}
