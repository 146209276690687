<template>
    <div id="add-pet-existing-client-fields">
        <div class="form-group">
            <div class="search-field form-group">
                <div class="dropdown bootstrap-select custom-select form-control">
                    <button type="button" class="btn dropdown-toggle btn-light" data-toggle="dropdown" role="combobox" aria-owns="bs-select-1" aria-haspopup="listbox" aria-expanded="false">
                        <div class="filter-option">
                            <div class="filter-option-inner">
                                <client-item v-if="selectedClient" :client="selectedClient"></client-item>
                                <template v-else>
                                    <span class="text">{{$t('Find existing client')}}...</span>
                                </template>
                            </div>
                        </div>
                    </button>
                    <div class="dropdown-menu" style="max-height: 300px !important; height: max-content !important; overflow-y: hidden !important;">
                        <div class="bs-searchbox">
                            <input v-model="searchTerm" type="search" class="form-control" autocomplete="off" role="combobox" aria-label="Search" aria-controls="bs-select-1" aria-autocomplete="list" :placeholder="$t('name, mobile, email, pet, breed')">
                            <span v-if="searchingClients" class="inline-loader"></span>
                        </div>
                        <div class="inner show" role="listbox" id="bs-select-1" tabindex="-1">
                            <ul class="dropdown-menu inner show" role="presentation" style="max-height: 217px !important; height: 100%; overflow-y: scroll !important">
                                <li v-for="s in sitesWithClients" :key="s.id">
                                    <label class="group-header" v-if="displaySiteName">{{s.name}}</label>
                                    <template v-for="c in s.clients">
                                        <a @click="selectClient(c)" role="option" class="dropdown-item" v-bind:class="{ 'active selected': c.id===selectedId }" id="bs-select-1-0" tabindex="0" aria-setsize="6" aria-posinset="1">
                                            <client-item :client="c"></client-item>
                                        </a>
                                    </template>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <new-pet-form v-if="selectedClient" :client="selectedClient"></new-pet-form>
        <div class="modal-footer d-flex w-100">
            <button @click="triggerSavePet" v-bind:disabled="addingPet" class="btn btn-primary" type="button">
                {{$t('Save')}}
                <span v-if="addingPet" class="inline-loader"></span>
            </button>
            <button @click="setDefault" class="btn btn-outline-secondary" data-dismiss="modal" type="button">{{$t('Cancel')}}</button>
        </div>
    </div>
</template>

<script>
    import { mapMutations, mapState } from 'vuex';
    import { ClientPetsSearchMixin } from "../../Clients/ClientPetsSearchMixin";
    import { ClientSearchMixinV2 } from "../../Shared/ClientSearchMixinV2";
    import { SiteCacheMixin } from '../../Shared/SiteCacheMixin';
    import { ArrayHelper } from '../../../helpers/ArrayHelper';
    import ClientDisplayItem from './ClientDisplayItem.vue';
    import NewPetForm from './NewPetForm.vue';

    export default {
        name: 'NewPet',
        mixins: [ClientPetsSearchMixin, ClientSearchMixinV2, SiteCacheMixin],
        data() {
            return {
                selectedClient: null,
                returnDefault: false
            }
        },
        computed: {
            ...mapState('PetsV2', [
                'addingPet'
            ]),
            selectedId() {
                return !!this.selectedClient ? this.selectedClient.id : '';
            },
            clientIds() {
                return this.clients.map(c => c.id);
            },
            sitesWithClients() {
                const clientsGroupedBySite = ArrayHelper.groupBy(this.clients, 'siteId');
                const result = [];
                for (var i = 0; i < clientsGroupedBySite.length; i++) {
                    const clientGroup = clientsGroupedBySite[i];
                    const site = this.sites.find(site => site.id === clientGroup.key);
                    if (site) {
                        const siteWithClients = { ...site, clients: clientGroup.values };
                        result.push(siteWithClients);
                    }
                }
                return result;
            },
            displaySiteName() {
                return this.sitesWithClients.length === 1 ? !this.sitesWithClients.some(swc => swc.id == this.siteId) : true;
            }
        },
        methods: {
            ...mapMutations('ApplicationEvents', [
                'triggerSavePet'
            ]),
            ...mapMutations('AppointmentScheduleUpload', [
                'setDefault'
            ]),
            selectClient(client) {
                this.selectedClient = client;
            }
        },
        components: {
            'client-item': ClientDisplayItem,
            'new-pet-form': NewPetForm
        }
    }
</script>
