<template>
    <div>
        <error-banner v-if="error" :message="error"></error-banner>
        <div class="d-flex flex-wrap flex-row flex-md-column">
            <tag-item v-for="tagType in selectedTagTypes" :tag-type="tagType" :isActive="true" :tags="tags" :key="tagType.id" class="mr-1 mb-1 mr-md-0">
                <template #active>
                    <i class="far fa-check"></i>
                </template>
                <template #inactive>
                    <i class="far fa-check"></i>
                </template>
            </tag-item>
        </div>
    </div>
</template>

<script>
    import { TagsCacheMixin } from "../TagsCacheMixin";
    import { TagTypesCacheMixin } from "../TagTypesCacheMixin";
    import TagItem from "./TagItem.vue";
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';

    export default {
        name: 'TagList',
        mixins: [TagTypesCacheMixin, TagsCacheMixin],
        computed: {
            selectedTagTypes() {
                return this.tags
                    .map(t => this.tagTypes.find(tt => tt.id === t.tagTypeId))
                    .filter(tt => !!tt)
                    .sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
            }
        },
        components: {
            'tag-item': TagItem,
            'error-banner': ErrorBanner
        }
    }
</script>