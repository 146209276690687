import { mapState, mapActions } from 'vuex';
import { ErrorHelper } from '../../helpers/ErrorHelper';

export const AppointmentScheduleDiscountsAddMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        scheduleId() {
            console.log('!!!OVERRIDE COMPUTED - scheduleId!!!');
        },
        ...mapState('AppointmentScheduleUpload', [
            'appointmentDiscounts'
        ]),
        appointmentDiscountsAddModel() {
            return {
                scheduleId: this.scheduleId,
                appointmentDiscounts: [...this.appointmentDiscounts]
            }
        },
        appointmentDiscountsAddModelValid() {
            const model = this.appointmentDiscountsAddModel;
            return !!model.scheduleId && model.appointmentDiscounts.length > 0;
        }
    },
    methods: {
        ...mapActions('AppointmentScheduleDiscounts', [
            'addAppointmentScheduleDiscounts'
        ]),
        tryAddAppointmentScheduleDiscounts() {
            const self = this;
            if (!self.appointmentDiscountsAddModelValid) {
                return;
            }
            self.error = '';
            return self.addAppointmentScheduleDiscounts({ key: self.scheduleId, payload: self.appointmentDiscountsAddModel })
                .then(response => self.afterAddAppointmentScheduleDiscounts(response.data))
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        afterAddAppointmentScheduleDiscounts() {
            //override if necessary
        }
    }
}
