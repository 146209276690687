<template>
    <div class="search-field">
        <input v-model="searchTerm" class="search-field-input form-control" :placeholder="$t('Search name, mobile, email, pet')" type="search">
        <button class="search-field-btn" type="submit">
            <span v-if="searchingPets" class="inline-loader"></span>
            <template v-else>
                <i class="fas fa-search"></i>
            </template>
        </button>
        <div v-if="petResultsReady" class="inner show" role="listbox" id="bs-select-1" tabindex="-1">
            <div class="dropdown-menu inner show" role="presentation">
                <div v-for="site in sitesWithPets" :key="site.id">
                    <label class="group-header" v-if="displaySiteName">{{site.name}}</label>
                    <div v-for="pet in site.pets" :key="pet.id">
                        <a @click="onClick(pet)" role="option" class="dropdown-item pointer" v-bind:class="{ 'active selected': pet.id===selectedId }">
                            <pet-result :pet="pet"></pet-result>
                        </a>
                    </div>
                </div>
                <div v-if="sitesWithPets.length === 0">
                    <a role="option" class="dropdown-item">
                        {{$t('No results found')}}
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { PetSearchMixinV2 } from "../../Shared/PetSearchMixinV2";
    import { PetClientsSearchMixin } from "./PetClientsSearchMixin";
    import { PetBreedsSearchMixin } from "../../Breeds/PetBreedsSearchMixin";
    import { SiteCacheMixin } from "../../Shared/SiteCacheMixin";
    import { ArrayHelper } from '../../../helpers/ArrayHelper.js';
    import PetDisplayItem from "./PetDisplayItem.vue";


    export default {
        name: 'PetSearchField',
        mixins: [PetSearchMixinV2, PetClientsSearchMixin, PetBreedsSearchMixin, SiteCacheMixin],
        props: {
            value: Object
        },
        computed: {
            selectedId() {
                return !!this.value ? this.value.id : '';
            },
            clientIds() {
                return this.pets.map(p => p.clientId);
            },
            breedIds() {
                return this.pets.map(p => p.breedId);
            },
            petsWithClients() {
                return this.pets.length > 0 ?
                    this.pets.filter(pet => {
                        const client = this.findClientCache(pet.clientId);
                        return client && client.value !== null;
                    }) : [];
            },
            sitesWithPets() {
                const petsGroupedBySite = this.petsWithClients.length > 0 ?
                    ArrayHelper.groupBy(this.petsWithClients, 'siteId') : [];
                const result = [];
                for (var i = 0; i < petsGroupedBySite.length; i++) {
                    const petGroup = petsGroupedBySite[i];
                    const site = this.sites.find(site => site.id === petGroup.key);
                    if (site) {
                        const siteWithPets = { ...site, pets: petGroup.values};
                        result.push(siteWithPets);
                    }
                }
                return result;
            },
            displaySiteName() {
                return this.sitesWithPets.length === 1 ? !this.sitesWithPets.some(swp => swp.id == this.siteId) : true;
            }
        },
        methods: {
            onClick(pet) {
                this.$emit('input', pet);
                this.searchTerm = '';
            }
        },
        components: {
            'pet-result': PetDisplayItem
        }
    }
</script>
