<template>
    <section class="mb-3">
        <div aria-expanded="false" class="accordion-header collapsed" data-target="#collapse2" data-toggle="collapse">
            {{$t('Total')}}
            <div class="total-figure ml-auto">
                {{totalString}}
            </div>
            <i class="far fa-chevron-down ml-3"></i>
        </div>
        <div class="collapse" id="collapse2">
            <div class="card card-body">
                <order-summary v-for="(order, index) in appointmentOrders" :index="index" :key="index"></order-summary>
                <payment-select></payment-select>
                <!-- Appointment Add-ons -->
                <div class="title-divider d-flex w-100 mt-3 mb-0" @click="showAddOns = !showAddOns">
                    <h2 class="p-2 mx-auto d-flex justify-content-center align-items-center">
                        {{$t('Add-ons')}}
                        <i :class="['ml-2', 'fal', showAddOns ? 'fa-chevron-circle-up' : 'fa-chevron-circle-down']"></i>
                    </h2>
                </div>
                <template v-if="showAddOns">
                    <table-mobile>
                        <add-on-item-mobile v-for="(addOnItem, index) in addOns" v-model="addOns" :index="index" :key="index" @removeAddOn="removeAddOn"></add-on-item-mobile>
                    </table-mobile>
                    <table-desktop class="mb-2">
                        <template #header>
                            <tr>
                                <th class="text-left">{{$t('No.')}}</th>
                                <th class="text-left">{{$t('Name')}}</th>
                                <th class="text-center">{{$t('Quantity')}}</th>
                                <th class="text-center">{{$t('Price')}}({{currencySymbol}})</th>
                                <th class="text-center">{{$t('Tax')}}({{currencySymbol}})</th>
                                <th class="text-center">{{$t('Action')}}</th>
                            </tr>
                        </template>
                        <template #body>
                            <add-on-item-desktop v-for="(addOnItem, index) in addOns" v-model="addOns" :index="index" :key="index" @removeAddOn="removeAddOn"></add-on-item-desktop>
                        </template>
                    </table-desktop>
                </template>
                <div v-if="withinMaxAddOnsLimit" class="w-100 d-flex justify-content-end w-100">
                    <button class="btn btn-sm btn-outline-secondary" type="button" @click="addAddOn">
                        <i class="fal fa-plus"></i>
                        {{$t('Add-on')}}
                    </button>
                </div>
                <!-- Appointment Discounts -->
                <div class="title-divider d-flex w-100 mt-3 mb-0" @click="showAppointmentDiscounts = !showAppointmentDiscounts">
                    <h2 class="p-2 mx-auto d-flex justify-content-center align-items-center">
                        {{$t('Discounts')}}
                        <i :class="['ml-2', 'fal', showAppointmentDiscounts ? 'fa-chevron-circle-up' : 'fa-chevron-circle-down']"></i>
                    </h2>
                </div>
                <template v-if="showAppointmentDiscounts">
                    <table-mobile>
                        <discount-item-mobile v-for="(discountItem, index) in discounts" v-model="discounts" :index="index" :key="index" @removeAppointmentDiscount="removeDiscount"></discount-item-mobile>
                    </table-mobile>
                    <table-desktop class="mb-2">
                        <template #header>
                            <tr>
                                <th class="text-left">{{$t('No.')}}</th>
                                <th class="text-left">{{$t('Is Percentage?')}}</th>
                                <th class="text-center">{{$t('Amount')}}</th>
                                <th class="text-center">{{$t('Action')}}</th>
                            </tr>
                        </template>
                        <template #body>
                            <discount-item-desktop v-for="(discountItem, index) in discounts" v-model="discounts" :index="index" :key="index" @removeAppointmentDiscount="removeDiscount"></discount-item-desktop>
                        </template>
                    </table-desktop>
                </template>
                <div v-if="withinMaxDiscountsLimit" class="w-100 d-flex justify-content-end w-100">
                    <button class="btn btn-sm btn-outline-secondary" type="button" @click="addDiscount">
                        <i class="fal fa-plus"></i>
                        {{$t('Discount')}}
                    </button>
                </div>
                <total-breakdown v-model="appointmentTotal"></total-breakdown>
            </div>
        </div>
    </section>
</template>

<script>
    import { mapState, mapMutations } from 'vuex';
    import { AppointmentTotalMixin } from '../../Shared/AppointmentTotalMixin';
    import { AppointmentTotalSettingsCacheMixin } from '../../../mixins/Appointments/AppointmentTotalSettingsCacheMixin';
    import AppointmentTotalOrderSummary from './AppointmentTotalOrderSummary.vue';
    import AppointmentPaymentSelect from './AppointmentPaymentSelect.vue';
    import AppointmentAddOnItemDesktop from './AppointmentAddOnItemDesktop.vue'
    import AppointmentAddOnItemMobile from './AppointmentAddOnItemMobile.vue'
    import AppointmentDiscountItemDesktop from './AppointmentDiscountItemDesktop.vue'
    import AppointmentDiscountItemMobile from './AppointmentDiscountItemMobile.vue'
    import AppointmentOverviewTotalBreakdown from '../ViewAppointment/AppointmentOverviewTotalBreakdown.vue'
    import TableDesktopNoBody from '../../Shared/Layout/TableDesktopNoBody.vue';
    import TableMobile from '../../Shared/Layout/TableMobile.vue';

    export default {
        name: 'AppointmentTotal',
        mixins: [AppointmentTotalMixin, AppointmentTotalSettingsCacheMixin],
        data() {
            return {
                showAddOns: false,
                showAppointmentDiscounts: false
            }
        },
        computed: {
            ...mapState('AppointmentScheduleUpload', [
                'appointmentOrders',
                'appointmentAddOns',
                'appointmentDiscounts'
            ]),
            orderItems() {
                var orderItems = [];
                for (var i = 0; i < this.appointmentOrders.length; i++) {
                    orderItems = orderItems.concat(this.appointmentOrders[i].orderItems);
                }
                return orderItems;
            },
            addOns: {
                get() {
                    return this.appointmentAddOns;
                },
                set(value) {
                    this.setAddOns(value);
                }
            },
            discounts: {
                get() {
                    return this.appointmentDiscounts;
                },
                set(value) {
                    this.setDiscounts(value);
                }
            },
            appointmentTotal() {
                return {
                    orderItems: this.orderItems,
                    addOns: this.addOns,
                    appointmentDiscounts: this.discounts
                }
            },
            withinMaxAddOnsLimit() {
                return this.addOns.length < this.maxAddOnsLimit;
            },
            withinMaxDiscountsLimit() {
                return this.appointmentDiscounts.length < this.maxDiscountsLimit;
            }
        },
        methods: {
            ...mapMutations('AppointmentScheduleUpload', [
                'setAddOns',
                'insertAddOn',
                'removeAddOn',
                'setDiscounts',
                'insertDiscount',
                'removeDiscount'
            ]),
            addAddOn() {
                this.showAddOns = true;
                if (!this.withinMaxAddOnsLimit) {
                    return;
                }
                this.insertAddOn({
                    id: Math.random().toString(36).substring(7),
                    name: "Default",
                    amount: 0.01,
                    quantity: 1,
                    newAddOn: true
                });
            },
            addDiscount() {
                this.showAppointmentDiscounts = true;
                if (!this.withinMaxDiscountsLimit) {
                    return;
                }
                this.insertDiscount({
                    id: Math.random().toString(36).substring(7),
                    isPercentage: false,
                    amount: 0.01,
                    newAppointmentDiscount: true
                });
            }
        },
        watch: {
            totalAmount(value) {
                this.$emit('input', value);
            }
        },
        components: {
            'table-mobile': TableMobile,
            'table-desktop': TableDesktopNoBody,
            'order-summary': AppointmentTotalOrderSummary,
            'payment-select': AppointmentPaymentSelect,
            'add-on-item-mobile': AppointmentAddOnItemMobile,
            'add-on-item-desktop': AppointmentAddOnItemDesktop,
            'discount-item-mobile': AppointmentDiscountItemMobile,
            'discount-item-desktop': AppointmentDiscountItemDesktop,
            'total-breakdown': AppointmentOverviewTotalBreakdown
        }
    }
</script>
