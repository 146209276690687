<template>
    <section class="mb-3">
        <div aria-expanded="false" class="accordion-header collapsed" data-target="#collapse2a" data-toggle="collapse">
            {{$t('Total')}}
            <div class="total-figure ml-auto">
                {{totalString}}
            </div>
            <i class="far fa-chevron-down ml-3"></i>
        </div>
        <div class="collapse" id="collapse2a">
            <div class="card card-body">
                <fieldset :disabled="disabled">
                    <section class="group">
                        <div class="row form-group">
                            <div class="col-md-4">
                                <label>{{$t('Payment type')}}</label>
                            </div>
                            <payment-method v-model="paymentMethodId"></payment-method>
                        </div>
                    </section>
                    <order-item v-for="(orderItem, index) in orderItems" v-model="orderItems" :index="index" :key="index" @removeItem="removeItem"></order-item>
                    <!-- Appointment Add-ons -->
                    <div class="title-divider d-flex w-100 mt-3 mb-0" @click="showAddOns = !showAddOns">
                        <h2 class="p-2 mx-auto d-flex justify-content-center align-items-center">
                            {{$t('Add-ons')}}
                            <i :class="['ml-2', 'fal', showAddOns ? 'fa-chevron-circle-up' : 'fa-chevron-circle-down']"></i>
                        </h2>
                    </div>
                    <template v-if="showAddOns">
                        <table-mobile>
                            <add-on-item-mobile v-for="(addOnItem, index) in addOns" v-model="addOns" :index="index" :key="index" @removeAddOn="removeAddOn"></add-on-item-mobile>
                        </table-mobile>
                        <table-desktop class="mb-2">
                            <template #header>
                                <tr>
                                    <th class="text-left">{{$t('No.')}}</th>
                                    <th class="text-left">{{$t('Name')}}</th>
                                    <th class="text-center">{{$t('Quantity')}}</th>
                                    <th class="text-center">{{$t('Price')}}({{currencySymbol}})</th>
                                    <th class="text-center">{{$t('Tax')}}({{currencySymbol}})</th>
                                    <th class="text-center">{{$t('Action')}}</th>
                                </tr>
                            </template>
                            <template #body>
                                <add-on-item-desktop v-for="(addOnItem, index) in addOns" v-model="addOns" :index="index" :key="index" @removeAddOn="removeAddOn"></add-on-item-desktop>
                            </template>
                        </table-desktop>
                    </template>
                    <div v-if="withinMaxAddOnsLimit" class="w-100 d-flex justify-content-end w-100">
                        <button class="btn btn-sm btn-outline-secondary" type="button" @click="insertAddOn">
                            <i class="fal fa-plus"></i>
                            {{$t('Add-on')}}
                        </button>
                    </div>
                    <!-- Appointment Discounts -->
                    <div class="title-divider d-flex w-100 mt-3 mb-0" @click="showAppointmentDiscounts = !showAppointmentDiscounts">
                        <h2 class="p-2 mx-auto d-flex justify-content-center align-items-center">
                            {{$t('Discounts')}}
                            <i :class="['ml-2', 'fal', showAppointmentDiscounts ? 'fa-chevron-circle-up' : 'fa-chevron-circle-down']"></i>
                        </h2>
                    </div>
                    <template v-if="showAppointmentDiscounts">
                        <table-mobile>
                            <discount-item-mobile v-for="(discountItem, index) in appointmentDiscounts" v-model="appointmentDiscounts" :index="index" :key="index" @removeAppointmentDiscount="removeAppointmentDiscount"></discount-item-mobile>
                        </table-mobile>
                        <table-desktop class="mb-2">
                            <template #header>
                                <tr>
                                    <th class="text-left">{{$t('No.')}}</th>
                                    <th class="text-left">{{$t('Is Percentage?')}}</th>
                                    <th class="text-center">{{$t('Amount')}}</th>
                                    <th class="text-center">{{$t('Action')}}</th>
                                </tr>
                            </template>
                            <template #body>
                                <discount-item-desktop v-for="(discountItem, index) in appointmentDiscounts" v-model="appointmentDiscounts" :index="index" :key="index" @removeAppointmentDiscount="removeAppointmentDiscount"></discount-item-desktop>
                            </template>
                        </table-desktop>
                    </template>
                    <div v-if="withinMaxDiscountsLimit" class="w-100 d-flex justify-content-end w-100">
                        <button class="btn btn-sm btn-outline-secondary" type="button" @click="insertAppointmentDiscount">
                            <i class="fal fa-plus"></i>
                            {{$t('Discount')}}
                        </button>
                    </div>
                    <total-breakdown v-model="value"></total-breakdown>
                </fieldset>
            </div>
        </div>
    </section>
</template>

<script>
    import { AppointmentTotalMixin } from '../../Shared/AppointmentTotalMixin.js';
    import { AppointmentTotalSettingsCacheMixin } from '../../../mixins/Appointments/AppointmentTotalSettingsCacheMixin';
    import AppointmentOverviewOrderItem from "./AppointmentOverviewOrderItem.vue";
    import AppointmentOverviewAddOnItemDesktop from "./AppointmentOverviewAddOnItemDesktop.vue"
    import AppointmentOverviewAddOnItemMobile from "./AppointmentOverviewAddOnItemMobile.vue"
    import AppointmentOverviewDiscountItemDesktop from "./AppointmentOverviewDiscountItemDesktop.vue"
    import AppointmentOverviewDiscountItemMobile from "./AppointmentOverviewDiscountItemMobile.vue"
    import AppointmentOverviewPaymentMethod from './AppointmentOverviewPaymentMethod.vue';
    import AppointmentOverviewTotalBreakdown from './AppointmentOverviewTotalBreakdown.vue'
    import TableDesktopNoBody from '../../Shared/Layout/TableDesktopNoBody.vue';
    import TableMobile from '../../Shared/Layout/TableMobile.vue';

    export default {
        name: 'AppointmentOverviewTotal',
        mixins: [AppointmentTotalMixin, AppointmentTotalSettingsCacheMixin],
        props: {
            value: Object,
            disabled: Boolean
        },
        data() {
            return {
                showAddOns: false,
                showAppointmentDiscounts: false
            }
        },
        computed: {
            paymentMethodId: {
                get() {
                    return this.value.paymentMethodId;
                },
                set(value) {
                    this.$emit('input', {
                        paymentMethodId: value,
                        orderItems: this.value.orderItems,
                        addOns: this.value.addOns,
                        appointmentDiscounts: this.value.appointmentDiscounts
                    });
                }
            },
            orderItems: {
                get() {
                    return this.value.orderItems;
                },
                set(value) {
                    this.$emit('input', {
                        paymentMethodId: this.value.paymentMethodId,
                        orderItems: value,
                        addOns: this.value.addOns,
                        appointmentDiscounts: this.value.appointmentDiscounts
                    });
                }
            },
            addOns: {
                get() {
                    return this.value.addOns;
                },
                set(value) {
                    this.$emit('input', {
                        paymentMethodId: this.value.paymentMethodId,
                        orderItems: this.value.orderItems,
                        addOns: value,
                        appointmentDiscounts: this.value.appointmentDiscounts
                    });
                }
            },
            appointmentDiscounts: {
                get() {
                    return this.value.appointmentDiscounts;
                },
                set(value) {
                    this.$emit('input', {
                        paymentMethodId: this.value.paymentMethodId,
                        orderItems: this.value.orderItems,
                        addOns: this.value.addOns,
                        appointmentDiscounts: value
                    });
                }
            },
            withinMaxAddOnsLimit() {
                return this.addOns.length < this.maxAddOnsLimit;
            },
            withinMaxDiscountsLimit() {
                return this.appointmentDiscounts.length < this.maxDiscountsLimit;
            }
        },
        methods: {
            removeItem(value) {
                if (!this.disabled) {
                    this.$emit('input', {
                        paymentMethodId: this.paymentMethodId,
                        orderItems: [...this.orderItems].filter(oi => oi.productId !== value.item.productId),
                        addOns: this.addOns,
                        appointmentDiscounts: this.appointmentDiscounts
                    });
                }
            },
            removeAddOn(value) {
                if (!this.disabled) {
                    this.$emit('input', {
                        paymentMethodId: this.paymentMethodId,
                        orderItems: this.orderItems,
                        addOns: [...this.addOns].filter(ao => ao.id !== value.addOn.id),
                        appointmentDiscounts: this.appointmentDiscounts
                    });
                }
            },
            removeAppointmentDiscount(value) {
                if (!this.disabled) {
                    this.$emit('input', {
                        paymentMethodId: this.paymentMethodId,
                        orderItems: this.orderItems,
                        addOns: this.addOns,
                        appointmentDiscounts: [...this.appointmentDiscounts].filter(ad => ad.id !== value.appointmentDiscount.id)
                    });
                }
            },
            insertAddOn() {
                this.showAddOns = true;
                if (!this.withinMaxAddOnsLimit) {
                    return;
                }
                this.addOns.push({
                    id: Math.random().toString(36).substring(7),
                    name: "Default",
                    amount: 0.01,
                    quantity: 1,
                    newAddOn: true
                });
            },
            insertAppointmentDiscount() {
                this.showAppointmentDiscounts = true;
                if (!this.withinMaxDiscountsLimit) {
                    return;
                }
                this.appointmentDiscounts.push({
                    id: Math.random().toString(36).substring(7),
                    isPercentage: false,
                    amount: 0.01,
                    newAppointmentDiscount: true
                });
            }
        },
        components: {
            'table-mobile': TableMobile,
            'table-desktop': TableDesktopNoBody,
            'order-item': AppointmentOverviewOrderItem,
            'payment-method': AppointmentOverviewPaymentMethod,
            'add-on-item-desktop': AppointmentOverviewAddOnItemDesktop,
            'add-on-item-mobile': AppointmentOverviewAddOnItemMobile,
            'discount-item-desktop': AppointmentOverviewDiscountItemDesktop,
            'discount-item-mobile': AppointmentOverviewDiscountItemMobile,
            'total-breakdown': AppointmentOverviewTotalBreakdown
        }
    }
</script>
