<template>
    <tr>
        <td>{{$t(name)}}</td>
        <td>{{created}}</td>
        <td>{{expires}}</td>
        <td>{{$t(status)}}</td>
        <td>{{message}}</td>
        <td class="text-center">
            <button v-if="completed" @click="downloadReport" class="btn btn-outline-secondary">
                <i class="fal fa-file-arrow-down"></i> {{$t('Download')}}
            </button>
        </td>
    </tr>
</template>

<script>
    import { ReportItemMixin } from './ReportItemMixin';

    export default {
        name: 'ReportItemDesktop',
        mixins: [ReportItemMixin],
        methods: {
            tryOpenReport() {
                // do nothing
            }
        }
    }
</script>
