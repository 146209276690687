import { Api } from "../../services/Api";

const state = () => ({
    tagTypesCaches: []
})

const getters = {
    findTagTypesCache: (state) => key => {
        return state.tagTypesCaches.find(ttc => ttc.key === key);
    }
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.tagTypesCaches.find(ttc => ttc.key === key);
        if (!!existing) {
            existing.retry = false;
            existing.loading = !!loading;
        } else {
            state.tagTypesCaches.push({ key: key, values: [], loading: !!loading });
        }
    },
    setTagTypes(state, { key, tagTypes }) {
        const existing = state.tagTypesCaches.find(ttc => ttc.key === key);
        if (!!existing) {
            existing.retry = false;
            existing.values = tagTypes;
            existing.loading = false;
        } else {
            state.tagTypesCaches.push({ key: key, values: tagTypes, loading: false });
        }
    },
    setRetry(state, key) {
        const existing = state.tagTypesCaches.find(ttc => ttc.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    },
    appendTagType(state, { key, tagType }) {
        const existing = state.tagTypesCaches.find(ttc => ttc.key === key);
        if (!!existing) {
            existing.values.push(tagType);
        } else {
            state.tagTypesCaches.push({ key: key, values: [tagType], loading: false });
        }
    },
    insertTagType(state, { key, tagType }) {
        const existing = state.tagTypesCaches.find(ttc => ttc.key === key);
        if (!!existing) {
            const old = existing.values.find(tt => tt.id === tagType.id);
            if (!!old) {
                const index = existing.values.indexOf(old);
                existing.values.splice(index, 1, tagType);
            } else {
                existing.values.push(tagType);
            }
        } else {
            state.tagTypesCaches.push({ key: key, values: [tagType], loading: false });
        }
    }
}

const actions = {
    findTagTypes({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.get('TagType?' + new URLSearchParams(payload))
                .then(response => {
                    commit('setTagTypes', { key: key, tagTypes: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', key);
                    reject(error);
                });
        });
    },
    addTagType({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.post('TagType', payload)
                .then(response => {
                    commit('appendTagType', { key: key, tagType: response.data });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    },
    updateTagType({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.put('TagType', payload)
                .then(response => {
                    commit('insertTagType', { key: key, tagType: response.data });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
