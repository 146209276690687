import { mapActions } from "vuex";
import { ErrorHelper } from "../../helpers/ErrorHelper";
import { TagHelper } from "../../helpers/TagHelper";
import { TagTypesCacheMixin } from "./TagTypesCacheMixin";

export const TagTypeUpdateMixin = {
    mixins: [TagTypesCacheMixin],
    data() {
        return {
            name: '',
            colour: '#1CA085',
            tagTypeUpdateValidator: TagHelper.getTagTypeAddValidator(),
            selectedTagType: null,
            updatedTagType: false
        }
    },
    computed: {
        tagTypeUpdateModel() {
            return {
                id: this.id,
                name: this.name,
                colour: this.colour
            }
        },
        tagTypeUpdateModelValid() {
            const model = this.tagTypeUpdateModel;
            return this.tagTypeUpdateValidator.isValid && model.id != '';
        },
        tagTypeUpdateModelModified() {
            return JSON.stringify(this.selectedTagType) !== JSON.stringify(this.tagTypeUpdateModel);
        }
    },
    watch: {
        selectedTagType() {
            this.refreshTagTypeUpdateModel();
        }
    },
    methods: {
        ...mapActions('TagTypes', [
            'updateTagType'
        ]),
        tryUpdateTagType() {
            const self = this;
            self.tagTypeUpdateValidator.check(self.tagTypeUpdateModel);
            if (!self.tagTypeUpdateModelValid) {
                return;
            }
            if (!self.tagTypeUpdateModelModified) {
                return;
            }
            self.error = '';
            self.updatedTagType = false;
            self.updateTagType({ key: self.tagTypesCacheKey, payload: self.tagTypeUpdateModel })
                .then(() => {
                    self.updatedTagType = true;
                })
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        refreshTagTypeUpdateModel() {
            this.id = !!this.selectedTagType ? this.selectedTagType.id : '';
            this.name = !!this.selectedTagType ? this.selectedTagType.name : '';
            this.colour = !!this.selectedTagType ? this.selectedTagType.colour : '#1CA085';
        }
    },
    created() {
        this.refreshTagTypeUpdateModel();
    }
}
