<template>
    <div class="form-group">
        <error-banner v-if="error" :message="error"></error-banner>
        <div class="d-flex flex-wrap">
            <tag-item v-for="tagType in tagTypes" :tag-type="tagType" :is-active="isActive(tagType)" :tags="tags" :key="tagType.id" @toggled="toggleTag" class="mr-1 mb-1">
                <template #active>
                    <i class="far fa-check"></i>
                </template>
                <template #inactive>
                    <i class="far fa-plus"></i>
                </template>
            </tag-item>
        </div>
    </div>
</template>

<script>
    import { TagUploadMixin } from "../TagUploadMixin";
    import TagItem from "./TagItem.vue";
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';

    export default {
        name: 'TagSelector',
        mixins: [TagUploadMixin],
        props: {
            tagTypes: Array
        },
        methods: {
            isActive(value) {
                return !!value && this.tags.some(t => t.tagTypeId === value.id);
            }
        },
        components: {
            'tag-item': TagItem,
            'error-banner': ErrorBanner
        }
    }
</script>
