<template>
    <div>
        <availability-alert :appointment="appointment"></availability-alert>
        <client-alert :client-id="clientId"></client-alert>
        <section class="rounded-card-white mb-3 pb-0">
            <client-overview :appointment="appointment"></client-overview>
            <pet-overview v-model="resourceId" :appointment="appointment" :order-items="orderItems" :disabled="disabled"></pet-overview>
        </section>
        <section class="rounded-card-white mb-3 pb-0">
            <site-select :disabled="true"></site-select>
            <overview-status v-model="status"></overview-status>
            <fieldset :disabled="disabled">
                <overview-notes v-model="loadingNote" :appointment="appointment"></overview-notes>
                <edit-order v-model="orderItems" :appointment="appointment" :disabled="disabled"></edit-order>
                <edit-date v-model="startUtc"></edit-date>
                <edit-duration v-model="duration"></edit-duration>
                <update-type v-model="updateTypeId"></update-type>
            </fieldset>
        </section>
        <edit-overrides v-model="overrides" :conflicts="conflicts" :loading="loadingConflicts"></edit-overrides>
        <error-banner v-if="error" :message="error"></error-banner>
        <overview-total v-model="orderEdit" :disabled="disabled"></overview-total>
        <overview-deposit v-model="loadingDeposit" :appointment="appointment" :disabled="disabled"></overview-deposit>
        <overview-checkout :appointment="appointment" :disabled="disabled"></overview-checkout>
        <overview-photos v-model="loadingImages" :appointment="appointment" :disabled="disabled"></overview-photos>
        <!--Appointment deletion confirm dialog-->
        <div v-if="deleting" role="alert" class="alert alert-warning  alert-icon">
            <div class="alert-content">
                <p>{{deleteMessage}}</p>
            </div>
            <button @click="tryDeleteAppointments" v-bind:disabled="loadingAppointment" type="button" class="btn btn-danger">
                {{$t('Yes')}}
            </button>
            <button @click="deleting=false" type="button" aria-label="Close" class="close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { AppointmentScheduleUpdateMixin } from '../../Shared/Appointments/AppointmentScheduleUpdateMixin';
    import { AppointmentScheduleOrdersUpdateMixin } from '../../Shared/Appointments/AppointmentScheduleOrdersUpdateMixin';
    import { AppointmentScheduleAddOnsUploadMixin } from '../../Shared/Appointments/AppointmentScheduleAddOnsUploadMixin';
    import { AppointmentScheduleDiscountsUploadMixin } from '../../Shared/Appointments/AppointmentScheduleDiscountsUploadMixin';
    import { AppointmentScheduleDeleteMixin } from '../../Shared/Appointments/AppointmentScheduleDeleteMixin';
    import AppointmentAlert from '../AppointmentAlert.vue';
    import AppointmentOverviewClient from './AppointmentOverviewClient.vue';
    import AppointmentOverviewStatus from './AppointmentOverviewStatus.vue';
    import AppointmentOverviewNotes from './AppointmentOverviewNotes.vue';
    import AppointmentOverviewTotal from './AppointmentOverviewTotal.vue';
    import AppointmentOverviewPet from './AppointmentOverviewPet.vue';
    import EditDateSelect from "../EditAppointment/EditDateSelect.vue";
    import EditOrder from "../EditAppointment/EditOrder.vue";
    import AppointmentUpdateTypeSelect from './AppointmentUpdateTypeSelect.vue';
    import AppointmentResourceAvailability from './AppointmentResourceAvailability.vue';
    import AppointmentOverviewImageAdd from './AppointmentOverviewImageAdd.vue';
    import SiteSelectColumn from '../../Shared/SiteSelectColumn.vue';
    import EditDuration from '../EditAppointment/EditDuration.vue';
    import EditOverrides from '../EditAppointment/EditOverrides.vue';
    import AppointmentOverviewCheckout from './AppointmentOverviewCheckout.vue';
    import AppointmentOverviewDeposit from './AppointmentOverviewDeposit.vue';
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';

    export default {
        name: 'AppointmentOverview',
        mixins: [AppointmentScheduleUpdateMixin, AppointmentScheduleOrdersUpdateMixin, AppointmentScheduleAddOnsUploadMixin, AppointmentScheduleDiscountsUploadMixin, AppointmentScheduleDeleteMixin],
        data() {
            return {
                loadingNote: false,
                loadingImages: false,
                loadingDeposit: false,
                deleting: false
            }
        },
        props: {
            id: String
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'saveAppointmentEvent',
                'deleteAppointmentEvent',
                'appoinmentDeletedEvent'
            ]),
            appointmentId() {
                return this.id;
            },
            clientId() {
                return !!this.appointment ? this.appointment.clientId : '';
            },
            loading() {
                return this.loadingOrders || this.loadingNote || this.loadingImages || this.loadingDeposit || this.loadingAddOns || this.loadingAppointmentDiscounts;
            },
            orderEdit: {
                get() {
                    return {
                        paymentMethodId: this.paymentMethodId,
                        orderItems: this.orderItems,
                        addOns: this.addOns,
                        appointmentDiscounts: this.appointmentDiscounts
                    }
                },
                set(value) {
                    this.paymentMethodId = value.paymentMethodId;
                    this.orderItems = value.orderItems;
                    this.addOns = value.addOns;
                    this.appointmentDiscounts = value.appointmentDiscounts;
                }
            },
            disabled() {
                return this.appointment.status.id !== 0;
            },
            deleteMessage() {
                switch (this.updateTypeId) {
                    case 2:
                        return "Are you sure you want to delete entire schedule?";
                    case 1:
                        return "Are you sure you want to delete appointment group?";
                    default:
                        return "Are you sure you want to delete just this appointment?";
                }
            }
        },
        watch: {
            loading(value) {
                this.$emit('input', value);
            },
            saveAppointmentEvent() {
                this.tryUpdateAppointments();
                this.tryUploadOrders();
                this.tryUploadAddOns();
                this.tryUploadAppointmentDiscounts();
            },
            deleteAppointmentEvent() {
                this.deleting = true;
            },
            appoinmentDeletedEvent() {
                this.deleting = false;
            },
        },
        components: {
            'site-select': SiteSelectColumn,
            'client-alert': AppointmentAlert,
            'client-overview': AppointmentOverviewClient,
            'pet-overview': AppointmentOverviewPet,
            'overview-status': AppointmentOverviewStatus,
            'overview-notes': AppointmentOverviewNotes,
            'overview-total': AppointmentOverviewTotal,
            'overview-deposit': AppointmentOverviewDeposit,
            'edit-order': EditOrder,
            'edit-date': EditDateSelect,
            'edit-duration': EditDuration,
            'update-type': AppointmentUpdateTypeSelect,
            'availability-alert': AppointmentResourceAvailability,
            'overview-photos': AppointmentOverviewImageAdd,
            'edit-overrides': EditOverrides,
            'overview-checkout': AppointmentOverviewCheckout,
            'error-banner': ErrorBanner
        }
    }
</script>
