<template>
    <div v-if="showTags">
        <display-tags :tags="campaignTags"></display-tags>
    </div>
</template>

<script>
    import { CampaignTagsMixin } from "../../../mixins/Campaigns/CampaignTagsMixin";
    import TagsDisplay from "../../Shared/Tags/TagsDisplay.vue";

    export default {
        name: 'CampaignTemplateTags',
        mixins: [CampaignTagsMixin],
        computed: {
            showTags() {
                return this.campaignTags.length > 0;
            }
        },
        components: {
            'display-tags': TagsDisplay
        }
    }
</script>
