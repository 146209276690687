import { Api } from "../../services/Api";

const state = () => ({
    appointmentScheduleDiscountCaches: []
})

const getters = {
    findAppointmentScheduleDiscountCache: (state) => (key) => {
        return state.appointmentScheduleDiscountCaches.find(asoc => asoc.key === key);
    }
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.appointmentScheduleDiscountCaches.find(asoc => asoc.key === key);
        if (!!existing) {
            existing.loading = !!loading;
        } else {
            state.appointmentScheduleDiscountCaches.push({ key: key, loading: !!loading });
        }
    }
}

const actions = {
    addAppointmentScheduleDiscounts({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.post('Schedule/AppointmentDiscount', payload)
                .then(response => {
                    const groupedDiscounts = response.data.reduce((ao, discount) => {
                        const appointmentId = discount.appointmentId;
                        if (!ao[appointmentId]) {
                            ao[appointmentId] = [];
                        }
                        ao[appointmentId].push(discount);
                        return ao;
                    }, {});
                    Object.entries(groupedDiscounts).forEach(([key, appointmentDiscounts]) => {
                        commit('AppointmentDiscounts/setAppointmentDiscounts', { key, appointmentDiscounts }, { root: true });
                    });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
