<template>
    <tr class="link pointer" v-bind:class="classType" @click="gotoSite">
        <td>
            <div class="name-widget">
                <div class="name-avatar">{{startLetter}}</div>
                <a class="text-truncate">{{siteName}}</a>
            </div>
        </td>
        <td class="text-truncate">{{companyName}}</td>
        <td>{{countryName}}</td>
        <td class="text-right">{{campaignRemainingCredits}}</td>
        <td class="text-right">
            <i v-if="lowSmsCredits" class="fal fa-exclamation-triangle"></i>
            {{smsRemainingCredits}}
        </td>
        <td class="text-right">{{taxRateString}}</td>
    </tr>
</template>

<script>
    import { SmsSettingCacheMixin } from '@/components/Communication/Settings/SmsSettingCacheMixin'
    import { CampaignSettingCacheMixin } from "../Communication/Settings/CampaignSettingCacheMixin";
    import { SiteItemMixin } from "./SiteItemMixin";
    import { TaxCacheMixinV2 } from "../../mixins/Tax/TaxCacheMixinV2";
    import { CountryCacheMixin } from '../Shared/Country/CountryCacheMixin';

    export default {
        name: 'SiteItemDesktop',
        mixins: [SiteItemMixin, TaxCacheMixinV2, SmsSettingCacheMixin, CountryCacheMixin, CampaignSettingCacheMixin],
        props: {
            number: Number
        },
        computed: {
            classType() {
                return this.number % 2 > 0 ? 'odd' : 'even';
            },
            siteId() {
                return this.site.id;
            },
            smsSetting() {
                return this.smsSettings.find(s => s.siteId == this.siteId);
            },
            smsRemainingCredits() {
                return !!this.smsSetting ? this.smsSetting.remainingCredits : 0;
            },
            lowSmsCreditsAlert() {
                return !!this.smsSetting ? this.smsSetting.balanceAlert : 20;
            },
            lowSmsCredits() {
                return !!this.smsSetting && this.smsRemainingCredits <= this.lowSmsCreditsAlert;
            },
            campaignSetting() {
                return this.campaignSettings.find(c => c.siteId == this.siteId);
            },
            campaignRemainingCredits() {
                return !!this.campaignSetting ? this.campaignSetting.remainingCredits : 0;
            },
            taxRate() {
                return !!this.tax ? this.tax.rate : 0;
            },
            taxRateString() {
                return this.taxRate.toString();
            }
        }
    }
</script>
