<template>
    <div>
        <error-banner v-if="error" :message="error"></error-banner>
        <div v-if="success" class="alert alert-success alert-icon" role="alert">
            <div class="alert-content">
                <p>
                    Your password has been set!
                </p>
            </div>
        </div>
        <template v-else>
            <fieldset>
                <password-input v-model="password" ref="passwordInput" :placeholder="$t('Please enter password')"></password-input>
                <validator-messages v-model="valid" :password="password"></validator-messages>
                <div class="form-group mt-4">
                    <button @click="submit" class="btn btn-primary w-100" v-bind:disabled="!valid">
                        {{$t('Set password')}}
                        <span v-if="loading" class="inline-loader"></span>
                    </button>
                </div>
            </fieldset>
        </template>
        <div v-if="success" class="row bottom-links">
            <div class="col">
                <router-link :to="{ name: 'auth' }">Login?</router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import { ErrorHelper } from "../../helpers/ErrorHelper";
    import { Api } from "../../services/Api";
    import { PasswordSetMixin } from "./PasswordSetMixin";
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';
    import PasswordInput from '../Shared/Credentials/PasswordInput.vue'
    import PasswordValidatorMessages from '../Shared/Credentials/PasswordValidatorMessages.vue'

    export default {
        name: 'PasswordSet',
        mixins: [PasswordSetMixin],
        methods: {
            submit() {
                const self = this;
                if (self.$refs.passwordInput) {
                    self.$refs.passwordInput.hidePassword();
                }
                if (!self.valid) {
                    return;
                }
                self.error = '';
                self.success = false;
                self.loading = true;
                Api.post('Credentials/Set', self.uploadModel)
                    .then(() => self.success = true)
                    .catch(error => self.error = ErrorHelper.getError(error))
                    .finally(() => self.loading = false);
            }
        },
        components: {
            'error-banner': ErrorBanner,
            'password-input': PasswordInput,
            'validator-messages': PasswordValidatorMessages
        }
    }
</script>