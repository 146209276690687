<template>
    <div class="appointment-overview-total-breakdown">
        <div class="title-divider d-flex w-100 mt-3 mb-0" @click="showBreakDown = !showBreakDown">
            <h2 class="p-2 mx-auto d-flex justify-content-center align-items-center">
                {{$t('Breakdown')}}
                <i :class="['ml-2', 'fal', showBreakDown ? 'fa-chevron-circle-up' : 'fa-chevron-circle-down']"></i>
            </h2>
        </div>
        <div v-if="showBreakDown">
            <div class="d-flex w-100 justify-content-center align-items-center flex-column">
                <ul class="col-12 col-md-8 list-unstyled mb-0">
                    <li class="d-flex justify-content-between">
                        <span>{{$t('Total Services')}}:</span>
                        <span>{{ currencySymbol }}{{ taxableAmount.toFixed(2) }}</span>
                    </li>
                    <li class="d-flex justify-content-between">
                        <span>{{$t('Total Add-ons')}}:</span>
                        <span>{{ currencySymbol }}{{ taxableAddOnAmount.toFixed(2) }}</span>
                    </li>
                    <li class="d-flex justify-content-between">
                        <span>{{$t('Total Tax for Services')}}:</span>
                        <span>{{ payableTaxString }}</span>
                    </li>
                    <li class="d-flex justify-content-between">
                        <span>{{$t('Total Tax for Add-ons')}}:</span>
                        <span>{{ payableAddOnTaxString }}</span>
                    </li>
                    <li class="d-flex justify-content-between">
                        <span>{{$t('Discounts')}}:</span>
                    </li>
                    <li>
                        <ul class="pl-3">
                            <li v-for="(discount, index) in discountBreakdown" :key="index" class="d-flex justify-content-between">
                                <span>{{ discount.name }}</span>
                                <span>- {{ currencySymbol }}{{ discount.value }}</span>
                            </li>
                        </ul>
                    </li>
                </ul>
                <div class="col-12 col-md-8 title-divider my-0"></div>
                <div class="col-12 col-md-8 d-flex justify-content-between">
                    <label>{{$t('Total')}}:</label>
                    <strong>{{ totalString }}</strong>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { AppointmentTotalMixin } from '../../Shared/AppointmentTotalMixin.js';

    export default {
        name: 'AppointmentOverviewTotalBreakdown',
        mixins: [AppointmentTotalMixin],
        props: {
            value: Object
        },
        data() {
            return {
                showBreakDown: true
            };
        },
        computed: {
            orderItems() {
                return this.value.orderItems;
            },
            addOns() {
                return this.value.addOns;
            },
            appointmentDiscounts() {
                return this.value.appointmentDiscounts;
            }
        }
    };
</script>
