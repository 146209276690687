<template>
    <section class="mb-3">
        <!-- Mobile will use this dropdown to change pet. Hide if only 1 pet -->
        <div v-if="pets.length > 1 || !value" class="d-md-none dropdown bootstrap-select form-control">
            <button type="button" data-toggle="dropdown" class="btn dropdown-toggle btn-light">
                {{petName}}
            </button>
            <div class="inner show">
                <ul class="dropdown-menu">
                    <li v-for="pet in pets"
                        :key="pet.id"
                        class="dropdown-item pointer text-truncate"
                        @click="select(pet)"
                        v-bind:class="{'active': pet.id === petId}">
                        <i v-if="pet.deceased" class="fal fa-tombstone mr-1"></i>
                        {{pet.name}}
                    </li>
                    <li class="dropdown-item pointer"
                        @click="select(null)"
                        v-bind:class="{'active': value === null}">
                        {{$t('Add pet')}}
                    </li>
                </ul>
            </div>
        </div>
        <!-- Desktop will use sidenav -->
        <nav class="pet-sidenav sidenav d-none d-md-block">
            <ul>
                <pet-list-item @clicked="select(pet)" v-for="pet in pets" :key="pet.id" :pet="pet" :is-selected-pet="selectedPet && selectedPet.id == pet.id"></pet-list-item>
                <li @click="select(null)" class="add-link">
                    <a>
                        <i class="fa-regular fa-circle-plus"></i>
                        <div>
                            {{$t('Add pet')}}
                        </div>
                    </a>
                </li>
            </ul>
        </nav>
    </section>
</template>

<script>
    import { mapState } from 'vuex';
    import process from 'process';
    import { PetBreedsSearchMixin } from "../Breeds/PetBreedsSearchMixin";
    import { ImageCachesMixin } from "../Shared/Images/ImageCachesMixin";
    import { PetCacheMixinV2 } from "../Shared/PetCacheMixinV2";
    import PetListItem from './PetListItem.vue';

    export default {
        name: 'PetOverviewPetList',
        mixins: [PetCacheMixinV2, ImageCachesMixin, PetBreedsSearchMixin],
        data() {
            return {
                selectedPet: null
            }
        },
        props: {
            value: Object,
            client: Object
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'petAddedEvent',
                'petUpdatedEvent',
                'petDeletedEvent'
            ]),
            pet: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.select(value);
                }
            },
            petName() {
                return !!this.pet ? this.pet.name : this.$t('Add pet');
            },
            petId() {
                return !!this.pet ? this.pet.id : '';
            },
            clientId() {
                return !!this.client ? this.client.id : '';
            },
            ownerIds() {
                return this.pets.map(p => p.id);
            },
            ownerTypeId() {
                return 3;
            },
            breedIds() {
                return this.pets.map(p => p.breedId);
            }
        },
        watch: {
            clientId() {
                this.select(this.pets[0]);
            },
            petAddedEvent(pet) {
                this.delaySelect(pet);
            },
            petUpdatedEvent(pet) {
                this.delaySelect(pet);
            },
            petDeletedEvent() {
                this.select(this.pets[0]);
            }
        },
        methods: {
            delaySelect(pet) {
                process.nextTick(() => {
                    const found = this.pets.find(p => p.id === pet.id);
                    if (!!found) {
                        this.select(found);
                    }
                });
            },
            select(pet) {
                this.selectedPet = pet;
                this.$emit('input', pet);
            }
        },
        components: {
            'pet-list-item': PetListItem
        },
        created() {
            if (this.pets.length > 0) {
                var pet = this.pets.find(p => p.id === this.$route.query.pet);
                if (!!pet) {
                    this.select(pet)
                }
                else {
                    this.select(this.pets[0])
                }
            }
        }
    }
</script>
