<template>
    <div class="input-group form-group">
        <div class="input-group-prepend">
            <span class="input-group-text"><i class="fal fa-lock-alt"></i></span>
        </div>
        <input v-model="password"
               v-bind:type="isPasswordVisible ? 'text' : 'password'"
               class="form-control password-field"
               :placeholder="placeholder"
               aria-label="Password"
               required
               @keydown.enter="keydownEnter">
        <div class="input-group-append">
            <span class="input-group-text" @click="togglePasswordVisibility" style="cursor: pointer;">
                <i :class="isPasswordVisible ? 'fal fa-eye' : 'fal fa-eye-slash'"></i>
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PasswordInput',
        props: {
            value: String,
            placeholder: String
        },
        data() {
            return {
                isPasswordVisible: false
            }
        },
        computed: {
            password: {
                get(){
                    return this.value;
                },
                set(value){
                    this.$emit('input', value);
                }
            }
        },
        methods: {
            togglePasswordVisibility() {
                this.isPasswordVisible = !this.isPasswordVisible;
            },
            hidePassword() {
                this.isPasswordVisible = false;
            },
            keydownEnter(){
                this.$emit('keydownEnter')
            }
        }
    }
</script>
