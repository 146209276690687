export const AppointmentDiscountHelper = {
    getAppointmentDiscountValidator() {
        return {
            isValid: false,
            appointmentDiscounts: [],
            check(model) {
                this.appointmentDiscounts = [];
                for (var i = 0; i < model.appointmentDiscounts.length; i++) {
                    const item = model.appointmentDiscounts[i];
                    const amount = parseFloat(item.amount);
                    this.appointmentDiscounts.push({
                        amount: isNaN(amount) || amount < 0 ? 'Please enter a valid amount.' : '',
                    })
                }
                this.isValid = !this.appointmentDiscounts.some(adi => !!adi.amount);
            }
        }
    }
}
