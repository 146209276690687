const state = () => ({
    //using state for global events instead of an event bus
    notificationBell: 0,
    showMobileNav: false,
    showAvailableTags: false,
    addAppointment: 0,
    addAppointmentWithDuration: 0,
    viewAppointment: null,
    savePetEvent: 0,
    petAddedEvent: null,
    petUpdatedEvent: null,
    deletePetEvent: 0,
    petDeletedEvent: 0,
    approveBookingEvent: 0,
    declineBookingEvent: 0,
    addClientEvent: 0,
    clientOnboardingInviteAddedEvent: null,
    clientAddedEvent: null,
    clientUpdatedEvent: null,
    updateClientEvent: 0,
    deleteClientEvent: 0,
    clientDeletedEvent: 0,
    deleteBookingEvent: 0,
    bookingDeletedEvent: 0,
    saveAppointmentScheduleEvent: 0,
    appointmentScheduleAddedEvent: null,
    clearAppointmentScheduleEvent: 0,
    saveAppointmentEvent: 0,
    deleteAppointmentEvent: 0,
    appoinmentDeletedEvent: 0,
    saveBreedEvent: 0,
    breedsAddedEvent: [],
    breedUpdatedEvent: null,
    breedDeletedEvent: 0,
    productsAddedEvent: [],
    productUpdatedEvent: null,
    productDeletedEvent: 0,
    saveSiteEvent: 0,
    saveSiteDefaultsEvent: 0,
    siteAddedEvent: null,
    saveUserEvent: 0,
    userAddedEvent: null,
    userUpdatedEvent: null,
    userDeletedEvent: 0,
    saveResourceEvent: 0,
    resourceAddedEvent: null,
    resourceDeletedEvent: 0,
    saveEventEvent: 0,
    eventsAddedEvent: [],
    eventUpdatedEvent: null,
    eventDeletedEvent: 0,
    saveScheduleSettingsEvent: 0,
    imagesAddedEvent: [],
    imageDeletedEvent: 0,
    weightAddedEvent: null,
    weightDeletedEvent: 0,
    contractTemplateAddedEvent: null,
    contractTemplateUpdatedEvent: null,
    userResourcesAddedEvent: [],
    addressAddedEvent: null,
    contractAddedEvent: null,
    contractDeletedEvent: 0,
    saveAppointmentResourcesEvent: 0,
    saveEmailMarketingTemplateEvent: 0,
    deleteEmailMarketingTemplateEvent: 0,
    changeAppointmentInScheduleViewEvent: null,
    storeTabChange: null
})

const mutations = {
    triggerNotificationBell(state) {
        state.notificationBell++;
    },
    toggleShowMobileNav(state) {
        state.showMobileNav = !state.showMobileNav;
    },
    toggleShowAvailableTags(state) {
        state.showAvailableTags = !state.showAvailableTags;
    },
    triggerAddAppointment(state) {
        state.addAppointment++;
    },
    triggerAddAppointmentWithDuration(state) {
        state.addAppointmentWithDuration++;
    },
    triggerViewAppointment(state, appointment) {
        state.viewAppointment = Object.assign({}, appointment);
    },
    triggerSavePet(state) {
        state.savePetEvent++;
    },
    triggerPetAdded(state, pet) {
        state.petAddedEvent = pet;
    },
    triggerPetUpdated(state, pet) {
        state.petUpdatedEvent = pet;
    },
    triggerDeletePet(state) {
        state.deletePetEvent++;
    },
    triggerPetDeleted(state) {
        state.petDeletedEvent++;
    },
    triggerClientOnboardingInviteAdded(state, invite) {
        state.clientOnboardingInviteAddedEvent = invite;
    },
    triggerApproveBooking(state) {
        state.approveBookingEvent++;
    },
    triggerDeclineBooking(state) {
        state.declineBookingEvent++;
    },
    triggerAddClient(state, args) {
        state.addClientEvent = Object.assign({}, args);
    },
    triggerClientAdded(state, client) {
        state.clientAddedEvent = client;
    },
    triggerUpdateClient(state) {
        state.updateClientEvent++;
    },
    triggerClientUpdated(state, client) {
        state.clientUpdatedEvent = client;
    },
    triggerDeleteClient(state) {
        state.deleteClientEvent++;
    },
    triggerClientDeleted(state) {
        state.clientDeletedEvent++;
    },
    triggerDeleteBooking(state) {
        state.deleteBookingEvent++;
    },
    triggerBookingDeleted(state) {
        state.bookingDeletedEvent++;
    },
    triggerSaveAppointmentSchedule(state) {
        state.saveAppointmentScheduleEvent++;
    },
    triggerAppointmentScheduleAdded(state, appointment) {
        state.appointmentScheduleAddedEvent = appointment;
    },
    triggerClearAppointmentSchedule(state) {
        state.clearAppointmentScheduleEvent++;
    },
    triggerSaveAppointment(state) {
        state.saveAppointmentEvent++;
    },
    triggerDeleteAppointment(state) {
        state.deleteAppointmentEvent++
    },
    triggerAppointmentDeleted(state) {
        state.appoinmentDeletedEvent++
    },
    triggerSaveBreed(state) {
        state.saveBreedEvent++;
    },
    triggerBreedsAdded(state, breeds) {
        state.breedsAddedEvent = breeds;
    },
    triggerBreedUpdated(state, breed) {
        state.breedUpdatedEvent = breed;
    },
    triggerBreedDeleted(state) {
        state.breedDeletedEvent++;
    },
    triggerProductsAdded(state, products) {
        state.productsAddedEvent = products;
    },
    triggerProductUpdated(state, product) {
        state.productUpdatedEvent = product;
    },
    triggerProductDeleted(state) {
        state.productDeletedEvent++;
    },
    triggerSaveSite(state) {
        state.saveSiteEvent++;
    },
    triggerSaveSiteDefaults(state) {
        state.saveSiteDefaultsEvent++;
    },
    triggerSiteAdded(state, site) {
        state.siteAddedEvent = site;
    },
    triggerSaveUser(state) {
        state.saveUserEvent++;
    },
    triggerUserAdded(state, user) {
        state.userAddedEvent = user;
    },
    triggerUserUpdated(state, user) {
        state.userUpdatedEvent = user;
    },
    triggerUserDeleted(state) {
        state.userDeletedEvent++;
    },
    triggerSaveResource(state) {
        state.saveResourceEvent++;
    },
    triggerResourceAdded(state, resource) {
        state.resourceAddedEvent = resource;
    },
    triggerResourceDeleted(state) {
        state.resourceDeletedEvent++;
    },
    triggerSaveEvent(state) {
        state.saveEventEvent++;
    },
    triggerEventsAdded(state, events) {
        state.eventsAddedEvent = events;
    },
    triggerEventUpdated(state, event) {
        state.eventUpdatedEvent = event;
    },
    triggerEventDeleted(state) {
        state.eventDeletedEvent++;
    },
    triggerSaveScheduleSettings(state) {
        state.saveScheduleSettingsEvent++;
    },
    triggerImagesAdded(state, images) {
        state.imagesAddedEvent = images;
    },
    triggerImageDeleted(state) {
        state.imageDeletedEvent++;
    },
    triggerWeightAdded(state, weight) {
        state.weightAddedEvent = weight;
    },
    triggerWeightDeleted(state) {
        state.weightDeletedEvent++;
    },
    triggerContractTemplateAdded(state, contractTemplate) {
        state.contractTemplateAddedEvent = contractTemplate;
    },
    triggerContractTemplateUpdated(state, contractTemplate) {
        state.contractTemplateUpdatedEvent = contractTemplate;
    },
    triggerUserResourcesAdded(state, resources) {
        state.userResourcesAddedEvent = resources;
    },
    triggerAddressAdded(state, address) {
        state.addressAddedEvent = address;
    },
    triggerContractAdded(state, contract) {
        state.contractAddedEvent = contract;
    },
    triggerContractDeleted(state) {
        state.contractDeletedEvent++;
    },
    triggerSaveAppointmentResources(state) {
        state.saveAppointmentResourcesEvent++;
    },
    triggerSaveEmailMarketingTemplate(state) {
        state.saveEmailMarketingTemplateEvent++;
    },
    triggerDeleteEmailMarketingTemplate(state) {
        state.deleteEmailMarketingTemplateEvent++;
    },
    triggerChangeAppointmentInScheduleView(state, appointment) {
        state.changeAppointmentInScheduleViewEvent = Object.assign({}, appointment);
    },
    triggerStoreTabChange(state, tabNumber) {
        state.storeTabChange = { tab: tabNumber };
    }
}

export default {
    namespaced: true,
    state,
    mutations
}
