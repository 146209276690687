import { mapActions } from "vuex";
import { ErrorHelper } from "../../helpers/ErrorHelper";
import { TagHelper } from "../../helpers/TagHelper";
import { TagTypesCacheMixin } from "./TagTypesCacheMixin";

export const TagTypeAddMixin = {
    mixins: [TagTypesCacheMixin],
    data() {
        return {
            name: '',
            colour: '#1CA085',
            entityTypeId: 0,
            tagTypeAddValidator: TagHelper.getTagTypeAddValidator(),
            addedTagType: false
        }
    },
    computed: {
        tagTypeAddModel() {
            return {
                name: this.name,
                colour: this.colour,
                entityTypeId: this.entityTypeId
            }
        }
    },
    methods: {
        ...mapActions('TagTypes', [
            'addTagType'
        ]),
        tryAddTagType() {
            const self = this;
            self.tagTypeAddValidator.check(self.tagTypeAddModel);
            if (!self.tagTypeAddValidator.isValid) {
                return;
            }
            self.error = '';
            self.addedTagType = false;
            self.addTagType({ key: self.tagTypesCacheKey, payload: self.tagTypeAddModel })
                .then(() => {
                    self.addedTagType = true;
                    self.resetTagTypeAddModel();
                })
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        resetTagTypeAddModel() {
            this.name = '';
            this.colour = '#1CA085';
            this.entityTypeId = 0;
        }
    }
}
