import { TaxCacheMixinV2 } from "../../mixins/Tax/TaxCacheMixinV2";
import { CountryCacheMixin } from "./Country/CountryCacheMixin";

export const AppointmentTotalMixin = {
    mixins: [TaxCacheMixinV2, CountryCacheMixin],
    computed: {
        addOns() {
            return [];
        },
        appointmentDiscounts() {
            return [];
        },
        orderItems() {
            console.log('!!!OVERRIDE COMPUTED - orderItems!!!');
        },
        taxableAmount() {
            return this.orderItems.map(s => s.amount * s.quantity).reduce((a, b) => a + b, 0);
        },
        taxableAddOnAmount() {
            return this.addOns.length > 0
                ? this.addOns.map(s => s.amount * s.quantity).reduce((a, b) => a + b, 0)
                : 0;
        },
        payableTax() {
            return !!this.tax ? this.taxableAmount * 0.01 * this.tax.rate : 0;
        },
        payableAddOnTax() {
            return !!this.tax ? this.taxableAddOnAmount * 0.01 * this.tax.rate : 0;
        },
        payableTaxString() {
            return this.currencySymbol + this.payableTax.toFixed(2).toString();
        },
        payableAddOnTaxString() {
            return this.currencySymbol + this.payableAddOnTax.toFixed(2).toString();
        },
        baseTotalAmount() {
            return this.taxableAmount + this.payableTax + this.taxableAddOnAmount + this.payableAddOnTax;
        },
        discountBreakdown() {
            const total = this.baseTotalAmount;
            const breakdown = [];

            if (this.appointmentDiscounts && this.appointmentDiscounts.length) {
                this.appointmentDiscounts.forEach(discount => {
                    let discountValue = 0;
                    let discountName = '';

                    if (discount.isPercentage) {
                        discountValue = total * (discount.amount / 100);
                        discountName = `Percentage (${discount.amount}%)`;
                    } else {
                        discountValue = discount.amount;
                        discountName = `Fixed (${this.currencySymbol}${discount.amount.toFixed(2)})`;
                    }

                    breakdown.push({
                        name: discountName,
                        value: discountValue.toFixed(2)
                    });
                });
            }

            return breakdown;
        },
        totalDiscountAmount() {
            return this.discountBreakdown.reduce((sum, d) => sum + parseFloat(d.value), 0);
        },
        totalAmount() {
            const total = this.baseTotalAmount - this.totalDiscountAmount;
            return Math.max(total, 0);
        },
        totalString() {
            return this.currencySymbol + this.totalAmount.toFixed(2).toString();
        }
    }
};
