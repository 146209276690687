import moment from 'moment';

export const AppointmentScheduleHelper = {
    getDefaultState(startUtc) {
        return {
            clientId: '',
            startUtc: startUtc,
            duration: 30,
            appointmentFrequencyId: 0,
            frequencyMultiple: 1,
            occurrences: 1,
            notes: '',
            deposit: 0,
            appointmentOrders: [
                {
                    pet: null,
                    resourceId: '',
                    paymentMethodId: 0,
                    orderItems: []
                }
            ],
            overrides: [],
            appointmentAddOns: [],
            appointmentDiscounts: []
        }
    },
    getRoundedTime() {
        const now = moment();
        now.minutes(Math.ceil(now.minutes() / 15) * 15);
        now.seconds(0);
        return now.utc().format();
    }
}
