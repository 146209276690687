<template>
    <div class="col">
        <div v-if="displayList">
            <table-desktop>
                <template #header>
                    <tr role="row">
                        <th>{{$t('Report')}}</th>
                        <th>{{$t('Created')}}</th>
                        <th>{{$t('Expiry')}}</th>
                        <th>{{$t('Status')}}</th>
                        <th>{{$t('Message')}}</th>
                        <th></th>
                    </tr>
                </template>
                <template #rows>
                    <report-desktop v-for="report in reports" :key="report.id" :report="report" :format-token="formatToken"></report-desktop>
                </template>
            </table-desktop>
            <table-mobile>
                <report-mobile v-for="report in reports" :key="report.id" :report="report" :format-token="formatToken"></report-mobile>
            </table-mobile>
        </div>
        <template v-else>
            <div v-if="fetchingReports" class="text-center">
                {{$t('Loading reports')}}...
            </div>
            <template v-else>
                <div class="text-center d-flex flex-column">
                    <div>{{$t('No reports to display.')}}</div>
                    <div>{{$t('Choose your filters then generate your selected report.')}}</div>
                    <div><i class="fa-light fa-circle-info"></i> <i>{{$t('Reports can take up to 10 minutes to generate.')}}</i></div>
                </div>
            </template>
        </template>
    </div>
</template>

<script>
    import TableDesktop from "../Shared/Layout/TableDesktop.vue";
    import TableMobile from "../Shared/Layout/TableMobile.vue";
    import { DateDisplayMixin } from "../Shared/Time/DateDisplayMixin";
    import { TimeDisplayMixin } from "../Shared/Time/TimeDisplayMixin";
    import ReportItemDesktop from "./ReportItemDesktop.vue";
    import ReportItemMobile from "./ReportItemMobile.vue";
    import { ReportsCacheMixin } from "./ReportsCacheMixin";

    export default {
        name: 'ReportList',
        mixins: [ReportsCacheMixin, DateDisplayMixin, TimeDisplayMixin],
        computed: {
            displayList() {
                return this.reports.length > 0;
            },
            formatToken() {
                return this.momentJSFormat + ' ' + this.timeMomentJSFormat;
            }
        },
        components: {
            'table-desktop': TableDesktop,
            'table-mobile': TableMobile,
            'report-desktop': ReportItemDesktop,
            'report-mobile': ReportItemMobile
        }
    }
</script>
