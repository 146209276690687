import { mapGetters, mapActions } from 'vuex';
import { ErrorHelper } from '../../../helpers/ErrorHelper';

export const AppointmentDiscountCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('AppointmentDiscounts', [
            'findAppointmentDiscountCache'
        ]),
        appointmentId() {
            console.log('OVERRIDE COMPUTED - appointmentId');
            return '';
        },
        appointmentDiscountFilterModel() {
            return {
                appointmentId: this.appointmentId
            }
        },
        appointmentDiscountFilterModelValid() {
            const model = this.appointmentDiscountFilterModel;
            return !!model.appointmentId;
        },
        appointmentDiscountCache() {
            return this.findAppointmentDiscountCache(this.appointmentId);
        },
        appointmentDiscountItems() {
            return !!this.appointmentDiscountCache ? this.appointmentDiscountCache.values : [];
        },
        loadingAppointmentDiscounts() {
            return !!this.appointmentDiscountCache && !!this.appointmentDiscountCache.loading;
        }
    },
    watch: {
        appointmentId() {
            this.refreshAppointmentDiscounts();
        }
    },
    methods: {
        ...mapActions('AppointmentDiscounts', [
            'findAppointmentDiscounts'
        ]),
        refreshAppointmentDiscounts() {
            if (!this.appointmentDiscountCache || !!this.appointmentDiscountCache.retry) {
                this.tryFindAppointmentDiscounts();
            }
        },
        tryFindAppointmentDiscounts() {
            const self = this;
            if (!self.appointmentDiscountFilterModelValid) {
                return;
            }
            self.error = '';
            self.findAppointmentDiscounts({ key: self.appointmentId, payload: self.appointmentDiscountFilterModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshAppointmentDiscounts();
    }
}
