<template>
    <section class="mb-3">
        <div aria-controls="collapseOne" class="accordion-header" data-target="#collapse-mpo-11" data-toggle="collapse">
            <i class="fas fa-server mr-2"></i>
            Job Scheduler
            <i class="far fa-chevron-down ml-auto"></i>
        </div>
        <div class="collapse" id="collapse-mpo-11">
            <div class="card card-body">
                <error-banner v-if="error" :message="error"></error-banner>
                <div v-if="completed" class="alert alert-success alert-icon" role="alert">
                    <div class="alert-content">
                        <p>Job Scheduler server has been successfully updated to <b>{{serverId}}</b>!</p>
                    </div>
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <i class="fa fa-times"></i>
                    </button>
                </div>
                <div class="form-group row">
                    <label class="col-auto">Current Server:</label>
                    <p><b>{{serverId}}</b></p>
                </div>
                <div class="form-group">
                    <label>Server ID:</label>
                    <input v-model="id" class="form-control" type="text">
                    <validation-message v-if="!!idMessage" :message="idMessage"></validation-message>
                </div>
                <div class="form-group">
                    <button @click="displayModal" class="btn btn-primary" type="button" v-bind:disabled="loadingJobScheduler">
                        Update
                    </button>
                </div>
            </div>
        </div>
        <button ref="displayButton" type="button" data-target="#system-admin-job-scheduler-modal" data-toggle="modal" style="display: none"></button>
        <modal-dialog id="system-admin-job-scheduler-modal" title="Are you sure?">
            <section class="rounded-card-white mb-3">
                <error-banner v-if="error" :message="error"></error-banner>
                <div role="alert" class="alert alert-warning alert-icon">
                    <div class="alert-content">
                        <p>Do you want to change the Job Scheduler server from <b>{{serverId}}</b> to <b>{{id}}</b>?</p>
                    </div>
                </div>
            </section>
            <div class="d-flex justify-content-center">
                <button @click="proceed" type="button" class="btn btn-primary mr-2" v-bind:disabled="loadingJobScheduler">
                    Yes
                    <span v-if="loadingJobScheduler" class="inline-loader"></span>
                </button>
                <button ref="cancelButton" type="button" class="btn btn-outline-secondary" data-dismiss="modal">No</button>
            </div>
        </modal-dialog>
    </section>
</template>

<script>
    import { ErrorHelper } from "../../helpers/ErrorHelper";
    import { mapActions } from "vuex";
    import { JobSchedulerCacheMixin } from "../../mixins/JobScheduler/JobSchedulerCacheMixin"
    import ErrorBanner from "../ErrorValidation/ErrorBanner.vue";
    import ValidationMessage from "../ErrorValidation/ValidationMessage.vue";
    import ModalDialog from "../Layout/ModalDialog.vue";

    export default {
        name: 'SystemAdminJobScheduler',
        mixins: [JobSchedulerCacheMixin],
        data() {
            return {
                id: '',
                completed: false,
                idMessage: ''
            }
        },
        computed: {
            jobSchedulerUpdateModel() {
                return {
                    serverId: this.id
                }
            },
            jobSchedulerUpdateModelValid() {
                const model = this.jobSchedulerUpdateModel;
                return !!model.serverId;
            }
        },
        methods: {
            ...mapActions('JobScheduler', [
                'updateJobScheduler'
            ]),
            displayModal() {
                this.$refs.displayButton.click();
            },
            proceed() {
                const self = this;
                self.completed = false;
                self.error = '';
                self.validate();
                if (!self.jobSchedulerUpdateModelValid || self.loadingJobScheduler) {
                    return;
                };
                self.updateJobScheduler(self.jobSchedulerUpdateModel)
                    .then(() => {
                        self.completed = true;
                        self.$refs.cancelButton.click();
                        self.clearJobSchedulerUpdateModel();
                    })
                    .catch(error => self.error = ErrorHelper.getError(error));
            },
            clearJobSchedulerUpdateModel() {
                this.id = '';
            },
            validate() {
                const self = this;
                const model = self.jobSchedulerUpdateModel
                self.idMessage = !!model.serverId ? '' : 'Please enter Server ID.';
            }
        },
        components: {
            'error-banner': ErrorBanner,
            'validation-message': ValidationMessage,
            'modal-dialog': ModalDialog
        }
    }
</script>
