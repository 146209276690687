<template>
    <tbody>
        <tr :class="classType" v-if="!editing">
            <td class="text-left">
                {{index + 1}}
                <i v-if="newAddOn" class="fal fa-star"></i>
            </td>
            <td class="text-left">
                {{name}}
            </td>
            <td class="text-center">{{quantity}}</td>
            <td class="text-right">{{amountInput}}</td>
            <td class="text-center">{{payableAddOnTaxString}}</td>
            <td class="d-flex align-items-center justify-content-center">
                <button @click="removeAddOn" type="button" class="btn btn-sm btn-secondary">
                    <i class="fal fa-trash text-white"></i>
                </button>
                <button @click="editing=true" type="button" class="btn btn-sm btn-outline-secondary ml-2">
                    <i class="fal fa-pencil"></i>
                </button>
            </td>
        </tr>
        <tr :class="classType" v-else>
            <td class="text-left">
                {{index + 1}}
                <i v-if="newAddOn" class="fal fa-star"></i>
            </td>
            <td>
                <input v-model="name" class="form-control text-left" :placeholder="$t('Name')">
            </td>
            <td>
                <input v-model="quantity" class="form-control text-center" type="number" :placeholder="$t('Quantity')">
            </td>
            <td>
                <input v-model="amountInput" class="form-control text-center" type="text" :placeholder="$t('Price')" @blur="processInput">
            </td>
            <td class="text-center">{{payableAddOnTaxString}}</td>
            <td class="d-flex align-items-center justify-content-center">
                <button @click="removeAddOn" type="button" class="btn btn-sm btn-outline-secondary">
                    <i class="fal fa-trash"></i>
                </button>
                <button @click="handleBack" type="button" class="btn btn-sm btn-outline-secondary ml-2">
                    <i class="fal fa-arrow-left"></i>
                </button>
            </td>
        </tr>
    </tbody>
</template>

<script>
    import { mapState } from 'vuex';
    import { AddOnItemMixin } from "../../Shared/AddOns/AddOnItemMixin";

    export default {
        name: 'AppointmentOverviewAddOnItemDesktop',
        mixins: [AddOnItemMixin],
        data() {
            return {
                editing: false
            }
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'saveAppointmentEvent'
            ]),
            classType() {
                return this.index % 2 > 0 ? 'odd' : 'even';
            }
        },
        watch: {
            saveAppointmentEvent() {
                this.editing = false
            }
        },
        created() {
            this.editing = !!this.newAddOn;
        }
    }
</script>
