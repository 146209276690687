<template>
    <div class="form-group">
        <error-banner v-if="error" :message="error"></error-banner>
        <div v-if="updatedTagType" class="alert alert-success alert-icon" role="alert">
            <div class="alert-content">
                <p>{{$t('Tag updated successfully!')}}</p>
            </div>
        </div>
        <tag-name v-model="name" :validator="tagTypeUpdateValidator"></tag-name>
        <tag-colour v-model="colour" :validator="tagTypeUpdateValidator"></tag-colour>
        <div class="text-center">
            <button @click="tryUpdateTagType" v-bind:disabled="loadingTagTypes" class="btn btn-primary mr-2" type="button">
                {{$t('Save')}}
                <span v-if="loadingTagTypes" class="inline-loader"></span>
            </button>
            <button @click="onCancel" class="btn btn-outline-secondary">
                {{$t('Cancel')}}
            </button>
        </div>
        <div class="title-divider mt-4 mb-2">
            <h2>{{$t('Pick a tag to edit')}}</h2>
        </div>
        <div class="d-flex flex-wrap">
            <tag-item v-for="tagType in sortedTagTypes" :tag-type="tagType" :is-active="isActive(tagType)" :tags="tags" :key="tagType.id" @toggled="toggleTag" class="mr-1 mb-1">
                <template #active>
                    <i class="far fa-pencil"></i>
                </template>
                <template #inactive>
                    <i v-if="tagType.entityType.id === 2" class="far fa-user"></i>
                    <i v-else-if="tagType.entityType.id === 3" class="far fa-dog"></i>
                    <i v-else class="fas fa-asterisk"></i>
                </template>
            </tag-item>
        </div>
    </div>
</template>

<script>
    import { TagTypeUpdateMixin } from "../TagTypeUpdateMixin";
    import { TagsCacheMixin } from "../TagsCacheMixin";
    import TagTypeNameInput from "./TagTypeNameInput.vue";
    import TagTypeColourInput from "./TagTypeColourInput.vue";
    import TagItem from "./TagItem.vue";
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';

    export default {
        name: 'TagTypeUpdate',
        mixins: [TagTypeUpdateMixin, TagsCacheMixin],
        computed: {
            sortedTagTypes() {
                return this.tagTypes.length > 0
                    ? this.tagTypes.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
                    : [];
            }
        },
        methods: {
            onCancel() {
                this.$emit('cancelled')
            },
            toggleTag(value) {
                this.selectedTagType = value;
            },
            isActive(value) {
                return !!this.selectedTagType ? this.selectedTagType.id == value.id : false;
            }
        },
        components: {
            'tag-name': TagTypeNameInput,
            'tag-colour': TagTypeColourInput,
            'tag-item': TagItem,
            'error-banner': ErrorBanner
        }
    }
</script>
