import { mapGetters, mapActions } from 'vuex';
import { ErrorHelper } from '../../../helpers/ErrorHelper';

export const AddOnCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('AddOns', [
            'findAddOnCache'
        ]),
        appointmentId() {
            console.log('OVERRIDE COMPUTED - appointmentId');
            return '';
        },
        addOnFilterModel() {
            return {
                appointmentId: this.appointmentId
            }
        },
        addOnFilterModelValid() {
            const model = this.addOnFilterModel;
            return !!model.appointmentId;
        },
        addOnCache() {
            return this.findAddOnCache(this.appointmentId);
        },
        addOnItems() {
            return !!this.addOnCache ? this.addOnCache.values : [];
        },
        loadingAddOns() {
            return !!this.addOnCache && !!this.addOnCache.loading;
        }
    },
    watch: {
        appointmentId() {
            this.refreshAddOns();
        }
    },
    methods: {
        ...mapActions('AddOns', [
            'findAddOns'
        ]),
        refreshAddOns() {
            if (!this.addOnCache || !!this.addOnCache.retry) {
                this.tryFindAddOns();
            }
        },
        tryFindAddOns() {
            const self = this;
            if (!self.addOnFilterModelValid) {
                return;
            }
            self.error = '';
            self.findAddOns({ key: self.appointmentId, payload: self.addOnFilterModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshAddOns();
    }
}
