<template>
    <div class="form-group">
        <div @change="onChange" class="w-100 btn-group btn-group-toggle" data-toggle="buttons">
            <label v-for="view in scheduleViews" class="btn btn-sm btn-outline-secondary" v-bind:class="{ active: scheduleView===view.value }">
                <input type="radio" :value="view.value" v-bind:checked="scheduleView==='view.value'">
                {{$t(view.name)}}
            </label>
        </div>
        <div v-if="isResourceScheduleView" class="mt-1 mb-2 px-1">
            <label>{{$t('Sort By:')}}</label>
            <div class="bootstrap-select form-control">
                <button type="button" data-toggle="dropdown" class="btn dropdown-toggle btn-light">
                    {{selectedOptionName}}
                </button>
                <div class="inner show">
                    <ul class="dropdown-menu">
                        <li v-for="option in options"
                            class="dropdown-item pointer"
                            :key="option.id"
                            @click="setResourceOrder(option.value)"
                            v-bind:class="{'active': option.id === selectedOptionId}">
                            {{$t(option.name)}}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { ScheduleViewsMixin } from '../ScheduleViewsMixin';
    import { CalendarViewSettingsMixin } from './CalendarViewSettingsMixin';

    export default {
        name: 'CalendarViewSettingsMobile',
        mixins: [CalendarViewSettingsMixin, ScheduleViewsMixin],
        computed: {
            isResourceScheduleView() {
                return this.scheduleView === 'resourceTimeGridDay';
            }
        },
        methods: {
            onChange(arg) {
                this.scheduleView = arg.target.value;
            }
        }
    }
</script>
