<template>
    <div>
        <error-banner v-if="error" :message="error"></error-banner>
        <language-select></language-select>
        <section class="basic-signup-info">
            <email-input v-model="email"></email-input>
            <password-validator v-model="password"></password-validator>
        </section>
        <section class="more-signup-info" :class="{'open': showMoreDetails }">
            <p>{{$t('We just need a few more details...')}}</p>
            <div class="form-group">
                <div class="row">
                    <div class="col-6">
                        <label class="sr-only" for="text">First name</label>
                        <input v-model="firstName" class="form-control" name="name" :placeholder="$t('First name')" type="text" value="">
                    </div>
                    <div class="col-6">
                        <label class="sr-only" for="text">Last name</label>
                        <input v-model="lastName" class="form-control" name="name" :placeholder="$t('Last name')" type="text" value="">
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label class="sr-only" for="text">Company name</label>
                <input v-model="companyName" class="form-control" name="name" :placeholder="$t('Company name')" type="text" value="">
            </div>
            <div class="form-group">
                <div v-if="country">
                    <div class="mb-1">
                        {{country.name}}
                    </div>
                    <a @click="country=null" class="link pointer">{{$t('Clear')}}</a>
                </div>
                <template v-else>
                    <country-search v-model="country"></country-search>
                </template>
            </div>
            <div class="form-group">
                <label class="custom-control custom-checkbox">
                    <input v-model="acceptedTCs" type="checkbox" class="custom-control-input">
                    <span class="custom-control-indicator"></span>
                    <span class="custom-control-description">{{$t('I agree with the')}} <a @click="gotoTerms" class="pointer">{{$t('Terms & Conditions')}}.</a></span>
                </label>
            </div>
            <div v-if="recaptchaEnabled" class="form-group">
                <vue-recaptcha ref="recaptcha" sitekey="6Lcyez4UAAAAAB0CbUGH_RZMHws77eZgJBT2LcFO" @verify="onRecaptchaSuccess" @expired="onRecaptchaFailed" @error="onRecaptchaFailed" />
            </div>
        </section>
        <div class="form-group mt-4">
            <button @click="tryRegistering" class="btn btn-secondary w-100" v-bind:disabled="disabled">
                {{$t('Create account')}}
                <span v-if="loading" class="inline-loader"></span>
            </button>
        </div>
        <div class="row bottom-links">
            <div class="col">
                {{$t('Already have an account?')}} <router-link :to="{ name: 'auth' }">{{$t('Login')}}</router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex';
    import { ErrorHelper } from '../../../helpers/ErrorHelper';
    import { VueRecaptcha } from 'vue-recaptcha';
    import { RegistrationSettingsMixin } from '../../Shared/RegistrationSettingsMixin';
    import EmailInput from '../../Shared/Credentials/EmailInput.vue';
    import PasswordValidator from '../../Shared/Credentials/PasswordValidator.vue';
    import CountryOptionSearch from '../../Shared/Country/CountryOptionSearch.vue';
    import router from '../../../routing';
    import LanguageSelect from '../../Account/LanguageSelect.vue';
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';

    export default {
        name: 'Register',
        mixins: [RegistrationSettingsMixin],
        data() {
            return {
                email: '',
                password: '',
                firstName: '',
                lastName: '',
                companyName: '',
                country: null,
                referralCode: '',
                acceptedTCs: false,
                recaptcha: ''
            }
        },
        computed: {
            ...mapState('Authentication', [
                'loading'
            ]),
            ...mapState('Sites', [
                'sites'
            ]),
            showMoreDetails() {
                return !!this.email && !!this.password;
            },
            disabled() {
                return !!this.loading || !!this.loadedRecaptchaSettings || !this.registrationModelValid;
            },
            registrationModel() {
                return {
                    email: this.email,
                    password: this.password,
                    firstName: this.firstName,
                    lastName: this.lastName,
                    companyName: this.companyName,
                    countryId: this.countryId,
                    recaptcha: this.recaptcha
                }
            },
            countryId() {
                return !!this.country ? this.country.id : '';
            },
            registrationModelValid() {
                const model = this.registrationModel;
                return this.acceptedTCs
                    && this.recaptchaValid
                    && !!model.email
                    && !!model.password
                    && !!model.firstName
                    && !!model.lastName
                    && !!model.companyName
                    && !!model.countryId;
            },
            recaptchaValid() {
                return !this.recaptchaEnabled || !!this.registrationModel.recaptcha;
            }
        },
        methods: {
            ...mapActions('Authentication', [
                'register'
            ]),
            tryRegistering() {
                const self = this;
                if (!self.registrationModelValid) {
                    return;
                }
                self.error = '';
                self.register(self.registrationModel)
                    .then(() => self.$router.push({
                        name: 'setup', params: {
                            firstName: this.firstName,
                            lastName: this.lastName,
                            email: this.email,
                            countryId: this.countryId,
                            referralCode: this.referralCode
                        }
                    }))
                    .catch(error => self.error = ErrorHelper.getError(error));
            },
            onRecaptchaSuccess(response) {
                this.recaptcha = response;
            },
            onRecaptchaFailed() {
                console.log('ReCaptcha failed');
                this.recaptcha = '';
            },
            gotoTerms() {
                const routeData = router.resolve({ name: 'terms' });
                window.open(routeData.href, '_blank');
            }
        },
        created() {
            const referral = this.$route.query.referral;
            if (!!referral) {
                this.referralCode = referral;
            }
            if (this.sites.length != 0) {
                window.location.reload();
            }
        },
        components: {
            'country-search': CountryOptionSearch,
            'email-input': EmailInput,
            'password-validator': PasswordValidator,
            'error-banner': ErrorBanner,
            'language-select': LanguageSelect,
            VueRecaptcha
        }
    }
</script>
