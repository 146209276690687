import { Api } from "../../services/Api";

const state = () => ({
    appointmentDiscountCaches: []
})

const getters = {
    findAppointmentDiscountCache: state => key => {
        return state.appointmentDiscountCaches.find(adc => adc.key === key);
    }
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.appointmentDiscountCaches.find(adc => adc.key === key);
        if (existing) {
            existing.loading = !!loading;
            existing.retry = false;
        } else {
            state.appointmentDiscountCaches.push({ key: key, values: [], loading: !!loading });
        }
    },
    setAppointmentDiscounts(state, { key, appointmentDiscounts }) {
        const existing = state.appointmentDiscountCaches.find(adc => adc.key === key);
        if (existing) {
            const index = state.appointmentDiscountCaches.indexOf(existing);
            state.appointmentDiscountCaches.splice(index, 1, { key: key, values: appointmentDiscounts, loading: false });
        } else {
            state.appointmentDiscountCaches.push({ key: key, values: appointmentDiscounts, loading: false });
        }
    },
    setRetry(state, key) {
        const existing = state.appointmentDiscountCaches.find(adc => adc.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    }
}

const actions = {
    findAppointmentDiscounts({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.post('AppointmentDiscount/Search', payload)
                .then(response => {
                    commit('setAppointmentDiscounts', { key: key, appointmentDiscounts: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', key);
                    reject(error);
                });
        });
    },
    uploadAppointmentDiscount({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.put('AppointmentDiscount', payload)
                .then(response => {
                    commit('setAppointmentDiscounts', { key: key, appointmentDiscounts: response.data });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    },
    uploadScheduleAppointmentDiscounts({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.put('Schedule/AppointmentDiscount', payload)
                .then(response => {
                    const groupedAppointmentDiscounts = response.data.reduce((ao, appointmentDiscount) => {
                        const appointmentId = appointmentDiscount.appointmentId;
                        if (!ao[appointmentId]) {
                            ao[appointmentId] = [];
                        }
                        ao[appointmentId].push(appointmentDiscount);
                        return ao;
                    }, {});
                    Object.entries(groupedAppointmentDiscounts).forEach(([key, appointmentDiscounts]) => {
                        commit('setAppointmentDiscounts', { key, appointmentDiscounts });
                    });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
