export const AddOnHelper = {
    getAddOnValidator() {
        return {
            isValid: false,
            addOns: [],
            check(model) {
                this.addOns = [];
                for (var i = 0; i < model.addOns.length; i++) {
                    const item = model.addOns[i];
                    const id = item.id;
                    const name = item.name;
                    const amount = parseFloat(item.amount);
                    const quantity = parseInt(item.quantity);
                    this.addOns.push({
                        id: id,
                        name: !!name ? '' : 'Please enter name.', 
                        amount: isNaN(amount) || amount < 0 ? 'Please enter a valid amount.' : '',
                        quantity: isNaN(quantity) || quantity < 1 ? 'Please enter a valid quantity.' : ''
                    })
                }
                this.isValid = !this.addOns.some(aoi => !!aoi.amount || !!aoi.quantity || !!aoi.name);
            }
        }
    }
}
