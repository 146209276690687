<template>
    <section class="mb-3">
        <div aria-expanded="true" class="filter-header" data-target="#mf-collapse-2" data-toggle="collapse">
            {{$t('Sites')}}
            <div class="filter-value ml-auto text-truncate">
                {{selectionString}}
            </div>
            <i class="far fa-chevron-down ml-3"></i>
        </div>
        <div id="mf-collapse-2" class="collapse show">
            <div class="filter-group">
                <label v-for="site in options" :key="site.id" class="custom-control custom-checkbox">
                    <input v-model="site.selected" @change="onOptionsChanged" class="custom-control-input" type="checkbox" v-bind:disabled="selected.id === site.id">
                    <span class="custom-control-indicator"></span>
                    <span class="custom-control-description">{{site.name}}</span>
                </label>
            </div>
        </div>
    </section>
</template>

<script>
    import { ScheduleFilterLocationMixin } from "./ScheduleFilterLocationMixin";

    export default {
        name: 'ScheduleMobileFiltersLocations',
        mixins: [ScheduleFilterLocationMixin],
        computed: {
            selectionString() {
                return this.selectedIds.map(id => this.sites.find(s => s.id === id)).filter(s => !!s).map(s => s.name).join(', ');
            }
        }
    }
</script>
