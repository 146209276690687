<template>
    <div class="main-page">
        <header class="header header-with-nav header-blue-mob">
            <div class="title-area">
                <h1 aria-expanded="false" aria-haspopup="true">
                    <i class="fa-duotone fa-globe fa-1x"></i>
                    {{$t('Bookings')}}
                    <small>({{bookingCountString}})</small>
                </h1>
            </div>
            <div class="secondary-nav">
                <div class="search-field-static">
                    <div class="search-field">
                        <input v-model="searchTerm" aria-label="Search" autocomplete="off" class="search-field-input" id="booking-search" :placeholder="$t('Search name, email, mobile')" spellcheck="false" type="search">
                        <button class="search-field-btn" type="submit">
                            <span v-if="searchingBookings" class="inline-loader"></span>
                            <template v-else>
                                <i class="fas fa-search"></i>
                            </template>
                        </button>
                    </div>
                </div>
            </div>
            <div class="header-tools">
                <site-select class="ml-auto mr-2 d-md-block d-none dropdown-site-selector"></site-select>
                <notification-bell></notification-bell>
            </div>
        </header>
        <div class="content">
            <site-select class="d-md-none d-block dropdown-site-selector"></site-select>
            <!-- Table shows for mobile, desktop has own view -->
            <bookings-mobile :search-key="searchKey" @skipTo="skipTo"></bookings-mobile>
            <!-- Table shows for desktop, mobile has own view -->
            <table-desktop :page-number="bookingPageNumber" @next="next" @previous="previous">
                <template #header>
                    <tr role="row">
                        <th class="col-1">{{$t('First Name')}}</th>
                        <th class="col-1">{{$t('Last Name')}}</th>
                        <th class="col-2">{{$t('Mobile')}}</th>
                        <th class="col-2">{{$t('Email')}}</th>
                        <th class="col-2" style="text-align: left">
                            <div class="dropdown">
                                <a role="button" data-toggle="dropdown">
                                    {{$t('Type')}}
                                    <i class="far fa-filter"></i>
                                </a>
                                <div class="dropdown-menu">
                                    <div v-for="clientType in clientTypes" class="dropdown-item" v-bind:class="{ 'active': clientTypeId===clientType.id }">
                                        <a class="pointer" @click="clientTypeId=clientType.id">{{$t(clientType.description)}}</a>
                                    </div>
                                </div>
                            </div>
                        </th>
                        <sort-header class="col-2" v-model="sortModel" key-name="createdDate">{{$t('Created')}}</sort-header>
                        <sort-header class="col-2" v-model="sortModel" key-name="appointmentDate">{{$t('Appointment')}}</sort-header>
                        <th class="col-1" style="text-align: left">
                            <div class="dropdown">
                                <a role="button" data-toggle="dropdown">
                                    {{$t('Status')}}
                                    <i class="far fa-filter"></i>
                                </a>
                                <div class="dropdown-menu">
                                    <div class="dropdown-item" v-bind:class="{ 'active': statusId===0 }">
                                        <a class="pointer" @click="statusId=0">{{$t('All')}}</a>
                                    </div>
                                    <div v-for="status in bookingStatuses" class="dropdown-item" v-bind:class="{ 'active': statusId===status.id }">
                                        <a class="pointer" @click="statusId=status.id">{{$t(status.description)}}</a>
                                    </div>
                                </div>
                            </div>
                        </th>
                    </tr>
                </template>
                <template #rows>
                    <booking-desk v-for="(booking, index) in bookings" :key="booking.id" :booking="booking" :number="index"></booking-desk>
                </template>
            </table-desktop>
        </div>
    </div>
</template>

<script>
    import { HashHelper } from '../../helpers/HashHelper';
    import { SiteIdMixin } from '../Shared/Site/SiteIdMixin';
    import { BookingCountCacheMixin } from '../Shared/BookingCountCacheMixin';
    import { BookingSearchMixin } from '../Shared/BookingSearchMixin';
    import { BookingStatusesCacheMixin } from '../../mixins/Bookings/BookingStatusesCacheMixin';
    import BookingItemDesktop from './BookingItemDesktop.vue';
    import TableDesktop from '../Shared/Layout/TableDesktop.vue';
    import TableSortableHeader from '../Shared/Layout/TableSortableHeader.vue';
    import SiteSelect from '../Account/SiteSelect.vue';
    import NotificationBell from '../Shared/Notifications/NotificationBell.vue';
    import BookingsMobile from './BookingsMobile.vue';

    export default {
        name: 'Bookings',
        mixins: [SiteIdMixin, BookingSearchMixin, BookingCountCacheMixin, BookingStatusesCacheMixin],
        data() {
            return {
                clientTypes: [
                    { id: 0, description: 'All' },
                    { id: 1, description: 'Existing' },
                    { id: 2, description: 'Unregistered' }
                ]
            }
        },
        computed: {
            searchKey() {
                return HashHelper.createStringHash(this.siteId + this.searchTerm + this.sortKey + this.ascending + this.statusId + this.clientTypeId) + '-';
            },
            bookingSearchKey() {
                return this.searchKey + this.skip + this.take;
            },
            bookingCountString() {
                return this.bookingCount ? this.bookingCount.count : '-';
            }
        },
        created() {
            this.refreshBookingSearch();
        },
        components: {
            'site-select': SiteSelect,
            'booking-desk': BookingItemDesktop,
            'table-desktop': TableDesktop,
            'sort-header': TableSortableHeader,
            'notification-bell': NotificationBell,
            'bookings-mobile': BookingsMobile
        }
    }
</script>
