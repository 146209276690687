import { ProductInputMixin } from "../../Products/ProductInputMixin";

export const AppointmentDiscountItemMixin = {
    mixins: [ProductInputMixin],
    props: {
        value: Array,
        index: Number
    },
    computed: {
        appointmentDiscount: {
            get() {
                return this.value[this.index];
            },
            set(value) {
                const appointmentDiscounts = [...this.value];
                appointmentDiscounts.splice(this.index, 1, value);
                this.$emit('input', appointmentDiscounts);
            }
        },
        isPercentage: {
            get() {
                return this.appointmentDiscount.isPercentage;
            },
            set(value) {
                this.appointmentDiscount = Object.assign({}, this.appointmentDiscount, {
                    isPercentage: value
                });
            }
        },
        newAppointmentDiscount() {
            return !!this.appointmentDiscount?.newAppointmentDiscount ? true : false;
        }
    },
    watch: {
        appointmentDiscount() {
            this.setInitial();
        },
        amount(value) {
            this.appointmentDiscount = Object.assign({}, this.appointmentDiscount, {
                amount: value
            });
        }
    },
    methods: {
        setInitial() {
            this.setInitialAmount(parseFloat(this.appointmentDiscount.amount));
        },
        removeAppointmentDiscount() {
            this.$emit('removeAppointmentDiscount', { index: this.index, appointmentDiscount: this.appointmentDiscount });
        }
    },
    created() {
        this.setInitial();
    }
}
