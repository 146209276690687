import { mapActions } from 'vuex';
import { ErrorHelper } from "../../../helpers/ErrorHelper";
import { AddOnHelper } from "../../../helpers/AddOnHelper";
import { AddOnCacheMixin } from "./AddOnCacheMixin";

export const AddOnUploadMixin = {
    mixins: [AddOnCacheMixin],
    data() {
        return {
            addOns: [],
            addOnValidator: AddOnHelper.getAddOnValidator()
        }
    },
    computed: {
        addOnUploadModel() {
            return {
                appointmentId: this.appointmentId,
                addOns: [...this.addOns]
            }
        },
        addOnUploadModelValid() {
            const model = this.addOnUploadModel;
            return !!model.appointmentId && this.addOnValidator.isValid;
        },
        addOnUploadModelModified() {
            return JSON.stringify(this.addOnUploadModel) !== JSON.stringify({
                appointmentId: this.appointmentId,
                addOns: [...this.addOnItems]
            });
        }
    },
    methods: {
        ...mapActions('AddOns', [
            'uploadAddOn'
        ]),
        tryUploadAddOn() {
            const self = this;
            self.addOnValidator.check(self.addOnUploadModel);
            if (!self.addOnUploadModelValid || !self.addOnUploadModelModified) {
                return;
            }
            self.error = '';
            self.uploadAddOn({ key: self.appointmentId, payload: self.addOnUploadModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
