import { mapActions } from 'vuex';
import { AppointmentCacheMixin } from "./AppointmentCacheMixin";
import { AppointmentDiscountUploadMixin } from '../AppointmentDiscounts/AppointmentDiscountUploadMixin';
import { ErrorHelper } from '../../../helpers/ErrorHelper';

export const AppointmentScheduleDiscountsUploadMixin = {
    mixins: [AppointmentCacheMixin, AppointmentDiscountUploadMixin],
    data() {
        return {
            updateTypeId: 0,
            previousUpdateTypeId: null
        }
    },
    computed: {
        scheduleId() {
            return !!this.appointment ? this.appointment.scheduleId : '';
        },
        petId() {
            return !!this.appointment ? this.appointment.petId : '';
        },
        sequenceNumber() {
            return !!this.appointment ? this.appointment.sequenceNumber : 0;
        },
        scheduleAppointmentDiscountUploadModel() {
            return {
                scheduleId: this.scheduleId,
                sequenceNumber: this.sequenceNumber,
                petId: this.petId,
                appointmentDiscounts: [...this.appointmentDiscounts]
            }
        },
        scheduleAppointmentDiscountUploadModelValid() {
            const model = this.scheduleAppointmentDiscountUploadModel;
            return !!model.scheduleId && !!model.petId && this.appointmentDiscountValidator.isValid;
        },
        scheduleAppointmentDiscountUploadModelModified() {
            return this.updateTypeId !== this.previousUpdateTypeId || this.appointmentDiscountUploadModelModified;
        }
    },
    watch: {
        appointmentDiscountItems() {
            this.refreshAppointmentDiscountItems();
        },
        appointmentId() {
            this.refreshAppointmentDiscountItems();
        }
    },
    methods: {
        ...mapActions('AppointmentDiscounts', [
            'uploadScheduleAppointmentDiscounts'
        ]),
        refreshAppointmentDiscountItems() {
            this.appointmentDiscounts = [...this.appointmentDiscountItems]
        },
        tryUploadAppointmentDiscounts() {
            if (this.updateTypeId === 2) {
                this.tryUploadScheduleAppointmentDiscounts();
            }
            else {
                this.tryUploadAppointmentDiscount();
            }
        },
        tryUploadScheduleAppointmentDiscounts() {
            const self = this;
            self.appointmentDiscountValidator.check(self.scheduleAppointmentDiscountUploadModel);
            if (!self.scheduleAppointmentDiscountUploadModelValid || !self.scheduleAppointmentDiscountUploadModelModified) {
                return;
            }
            self.error = '';
            self.uploadScheduleAppointmentDiscounts({ key: self.appointmentId, payload: self.scheduleAppointmentDiscountUploadModel })
                .then(() => self.previousUpdateTypeId = self.updateTypeId)
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshAppointmentDiscountItems();
    }
}
