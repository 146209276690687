import { Api } from "../../services/Api";

const state = () => ({
    fetchedAppointmentTotalSettings: false,
    fetchingAppointmentTotalSettings: false,
    appointmentTotalSettings: null
})

const mutations = {
    setFetching(state, fetching) {
        state.fetchingAppointmentTotalSettings = !!fetching;
        state.fetchedAppointmentTotalSettings = false;
    },
    setAppointmentTotalSettings(state, appointmentTotalSettings) {
        state.appointmentTotalSettings = appointmentTotalSettings;
        state.fetchingAppointmentTotalSettings = false;
        state.fetchedAppointmentTotalSettings = true;
    }
}

const actions = {
    fetchAppointmentTotalSettings({ commit }) {
        return new Promise((resolve, reject) => {
            commit('setFetching', true);
            Api.get('AppointmentTotal/Setting')
                .then(response => {
                    commit('setAppointmentTotalSettings', response.data);
                    resolve(response);
                })
                .catch(error => {
                    commit('setFetching', false);
                    reject(error);
                });
        });
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
