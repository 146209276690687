<template>
    <div class="row form-group">
        <div class="col-md-4">
            <label for="title">{{$t('Resources')}}<span class="required-field"></span></label>
        </div>
        <div class="col-md-8">
            <div class="dropdown">
                <button aria-expanded="false"
                        aria-haspopup="true"
                        class="filter-dd text-left w-100 mw-400"
                        data-toggle="dropdown"
                        type="button">
                    <multi-select-display :values="summary"></multi-select-display>
                </button>
                <div aria-labelledby="dropdownMenuButton" class="dropdown-menu py-1 w-100 mw-400">
                    <div>
                        <div v-for="resource in options" :key="resource.id" class="dropdown-item px-2">
                            <label class="custom-control custom-checkbox">
                                <input v-model="resource.selected" @change="onChange" type="checkbox" class="custom-control-input">
                                <span class="custom-control-indicator"></span>
                                <span class="custom-control-description">{{resource.name}}</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group has-error has-feedback">
                <validation-message v-if="validator.resourceIds" :message="validator.resourceIds"></validation-message>
            </div>
        </div>
    </div>
</template>

<script>
    import { ResourceCacheMixin } from "../../Shared/Resources/ResourceCacheMixin";
    import ValidationMessage from '@/components/ErrorValidation/ValidationMessage.vue';
    import MultiSelectDropdownDisplay from "../../Shared/Layout/MultiSelectDropdownDisplay.vue"

    export default {
        name: 'EventResourceSelect',
        mixins: [ResourceCacheMixin],
        props: {
            value: Array,
            validator: Object
        },
        computed: {
            options() {
                return this.resources.map(r => Object.assign({}, r, {
                    selected: this.value.indexOf(r.id) > -1
                }));
            },
            selectedOptions() {
                return this.options.filter(o => o.selected);
            },
            summary() {
                return this.selectedOptions.length > 0 ? this.selectedOptions.map(o => o.name) : ['Please select...'];
            },
        },
        methods: {
            onChange() {
                this.$emit('input', this.options.filter(o => o.selected).map(o => o.id));
            }
        },
        components: {
            'validation-message': ValidationMessage,
            'multi-select-display': MultiSelectDropdownDisplay
        }
    }
</script>
