import { ReportCsvCacheMixin } from "./ReportCsvCacheMixin";
import moment from 'moment';

export const ReportItemMixin = {
    mixins: [ReportCsvCacheMixin],
    props: {
        formatToken: String
    },
    computed: {
        name() {
            return this.report.type.description;
        },
        created() {
            return moment(this.report.createdUtc).format(this.formatToken);
        },
        expires() {
            return !!this.report.expiresUtc ? moment(this.report.expiresUtc).format(this.formatToken) : '';
        },
        status() {
            return this.report.status.id ? this.report.status.description : 'Building';
        },
        message() {
            return this.report.message;
        },
        completed() {
            return this.report.status.id === 1;
        }
    },
    watch: {
        reportCsv() {
            this.tryOpenReport();
        }
    },
    methods: {
        downloadReport() {
            if (!!this.reportCsv) {
                this.tryOpenReport();
            } else {
                this.refreshReportCsv();
            }
        },
        tryOpenReport() {
            if (!this.reportCsv) {
                return;
            }
            const blob = new Blob([this.reportCsv], { type: 'text/csv' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = this.name;
            link.click();
            URL.revokeObjectURL(link.href);
        }
    }
}
