import { mapState, mapActions } from 'vuex';
import { ErrorHelper } from '../../helpers/ErrorHelper';

export const JobSchedulerCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('JobScheduler', [
            'fetchedJobScheduler',
            'loadingJobScheduler',
            'jobScheduler'
        ]),
        serverId() {
            return !!this.jobScheduler ? this.jobScheduler.serverId : '';
        }
    },
    methods: {
        ...mapActions('JobScheduler', [
            'fetchJobScheduler'
        ]),
        tryFetchJobScheduler() {
            const self = this;
            self.fetchJobScheduler()
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        refreshJobScheduler() {
            if (!this.fetchedJobScheduler && !this.loadingJobScheduler) {
                this.tryFetchJobScheduler();
            }
        }
    },
    created() {
        this.refreshJobScheduler();
    }
}
