<template>
    <div class="tab-pane fade show active">
        <error-banner v-if="error" :message="error"></error-banner>
        <client-alert :client-id="clientId"></client-alert>
        <grooming-details></grooming-details>
        <more-options></more-options>
        <total-list v-model="totalAmount"></total-list>
        <deposit-input :total="totalAmount"></deposit-input>
        <overrides :conflicts="conflicts" :loading="loadingConflicts"></overrides>
        <div class="modal-footer d-flex w-100">
            <button @click="triggerSaveAppointmentSchedule" v-bind:disabled="addingSchedule" class="btn btn-primary" type="button">
                {{$t('Save')}}
                <span v-if="addingSchedule" class="inline-loader"></span>
            </button>
            <button @click="setDefault" class="btn btn-outline-secondary" data-dismiss="modal" type="button">{{$t('Cancel')}}</button>
        </div>
    </div>
</template>

<script>
    import { mapMutations, mapState } from 'vuex';
    import { AppointmentScheduleAddMixin } from '../../Shared/AppointmentScheduleAddMixin.js';
    import { AppointmentScheduleNoteAddMixin } from '../../Shared/AppointmentScheduleNoteAddMixin';
    import { AppointmentScheduleOrdersAddMixin } from '../../Shared/AppointmentScheduleOrdersAddMixin';
    import { AppointmentScheduleAddOnsAddMixin } from '../../Shared/AppointmentScheduleAddOnsAddMixin';
    import { AppointmentScheduleDiscountsAddMixin } from '../../Shared/AppointmentScheduleDiscountsAddMixin';
    import AppointmentOptions from './AppointmentOptions.vue';
    import AppointmentTotal from './AppointmentTotal.vue';
    import AppointmentOverrides from './AppointmentOverrides.vue';
    import AppointmentDetailsGrooming from './AppointmentDetailsGrooming.vue';
    import AppointmentAlert from '../AppointmentAlert.vue';
    import AppointmentDepositInput from './AppointmentDepositInput.vue';
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';

    export default {
        name: 'AddAppointmentExisting',
        mixins: [AppointmentScheduleAddMixin, AppointmentScheduleNoteAddMixin, AppointmentScheduleOrdersAddMixin, AppointmentScheduleAddOnsAddMixin, AppointmentScheduleDiscountsAddMixin],
        data() {
            return {
                addedSchedule: null,
                totalAmount: 0,
                selectedSavingType: 0
            }
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'saveAppointmentScheduleEvent'
            ]),
            ...mapState('AppointmentSchedules', [
                'addingSchedule'
            ]),
            clientId() {
                return this.appointmentScheduleAddModel.clientId;
            },
            scheduleId() {
                return !!this.addedSchedule ? this.addedSchedule.id : '';
            },
            ownerId() {
                return this.scheduleId;
            }
        },
        methods: {
            ...mapMutations('AppointmentScheduleUpload', [
                'setDefault'
            ]),
            ...mapMutations('ApplicationEvents', [
                'triggerSaveAppointmentSchedule'
            ])
        },
        watch: {
            saveAppointmentScheduleEvent() {
                this.tryAddAppointmentSchedule();
            },
            appointmentScheduleAddedEvent(appointmentSchedule) {
                this.addedSchedule = appointmentSchedule;
                this.noteText = this.notes;
                this.tryAddNote();
                this.tryAddAppointmentScheduleOrders();
                this.tryAddAppointmentScheduleAddOns();
                this.tryAddAppointmentScheduleDiscounts();
            }
        },
        components: {
            'grooming-details': AppointmentDetailsGrooming,
            'more-options': AppointmentOptions,
            'total-list': AppointmentTotal,
            'deposit-input': AppointmentDepositInput,
            'overrides': AppointmentOverrides,
            'client-alert': AppointmentAlert,
            'error-banner': ErrorBanner
        }
    }
</script>
