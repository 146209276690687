<template>
    <div>
        <label v-if="!atLeast10Characters" class="auth">{{$t('At least 10 characters')}}</label>
        <label v-if="!atLeast1Uppercase" class="auth">{{$t('At least 1 uppercase')}}</label>
        <label v-if="!atLeast1Number" class="auth">{{$t('At least 1 number')}}</label>
    </div>
</template>

<script>
    export default {
        name: 'PasswordValidatorMessages',
        props: {
            value: Boolean,
            password: String
        },
        computed: {
            atLeast10Characters() {
                return this.password.length > 9;
            },
            atLeast1Uppercase() {
                return /[A-Z]/.test(this.password);
            },
            atLeast1Number() {
                return /\d/.test(this.password);
            },
            valid() {
                return !!this.atLeast1Number && !!this.atLeast1Uppercase && !!this.atLeast10Characters
            }
        },
        watch: {
            valid() {
                this.emitValid();
            }
        },
        methods: {
            emitValid() {
                this.$emit('input', this.valid);
            }
        },
        created() {
            this.emitValid();
        }
    }
</script>
