<template>
    <modal-dialog id="appointment-view" :title="title" :subTitle="dateTimeString">
        <div class="modal-appointment-view" v-bind:style="{'border-color': appointmentStatusColor}">
            <div class="modal-appointment-view-content">
                <error-banner v-if="error" :message="error"></error-banner>
                <!--Navigation tabs-->
                <ul class="nav nav-tabs-ps" id="myTab">
                    <li class="nav-item">
                        <a class="nav-link pointer" v-bind:class="{ active: tab===0 }" @click="tab=0">{{$t('Overview')}}</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link pointer" v-bind:class="{ active: tab===1 }" @click="tab=1">{{$t('Client info')}}</a>
                    </li>
                </ul>
                <div v-if="appointment" class="tab-content">
                    <br />
                    <!-- Overview -->
                    <appointment-overview v-if="tab===0" v-model="loadingOverview" :id="appointmentId"></appointment-overview>
                    <!-- Client info-->
                    <client-info v-if="tab===1" v-model="loadingClientInfo" :appointment="appointment"></client-info>
                </div>
                <div class="modal-footer d-flex w-100 d-flex justify-content-between">
                    <div class="">
                        <button @click="triggerSaveAppointment" v-bind:disabled="loading" class="btn btn-primary" type="button">
                            {{$t('Save')}}
                            <span v-if="loading" class="inline-loader"></span>
                        </button>
                        <button class="btn btn-outline-secondary" @click="cancelClicked" type="button">{{$t('Cancel')}}</button>
                    </div>
                    <delete-button @clicked="triggerDeleteAppointment"></delete-button>
                </div>
            </div>
        </div>
        <button ref="closeModal" data-dismiss="modal" style="display: none"></button>
    </modal-dialog>
</template>

<script>
    import moment from 'moment';
    import { mapState, mapMutations } from 'vuex';
    import { DateDisplayMixin } from '../../Shared/Time/DateDisplayMixin';
    import { TimeDisplayMixin } from '../../Shared/Time/TimeDisplayMixin';
    import { PetDisplayMixin } from '../../Shared/Appointments/PetDisplayMixin';
    import { AppointmentCacheMixin } from '../../Shared/Appointments/AppointmentCacheMixin';
    import { CalendarHelper } from '../../../helpers/CalendarHelper';
    import ModalDialog from '../../Layout/ModalDialog.vue';
    import AppointmentOverview from './AppointmentOverview.vue';
    import AppointmentClientInfo from './AppointmentClientInfo.vue';
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';
    import DeleteButton from '../../Shared/DeleteButton.vue';

    export default {
        name: 'ScheduleViewAppointment',
        mixins: [AppointmentCacheMixin, DateDisplayMixin, TimeDisplayMixin, PetDisplayMixin],
        data() {
            return {
                tab: 0,
                loadingOverview: false,
                loadingClientInfo: false
            }
        },
        props: {
            id: String
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'appoinmentDeletedEvent',
                'changeAppointmentInScheduleViewEvent'
            ]),
            appointmentId() {
                return this.id;
            },
            clientId() {
                return !!this.appointment ? this.appointment.clientId : '';
            },
            petId() {
                return !!this.appointment ? this.appointment.petId : '';
            },
            dateTimeString() {
                if (!this.appointment) {
                    return '-';
                }
                const start = moment(this.appointment.startUtc).format('ddd, ' + this.momentJSFormat + ' - ' + this.timeMomentJSFormat);
                const end = moment(this.appointment.endUtc).format(' - ' + this.timeMomentJSFormat);
                return start + end;
            },
            loading() {
                return this.loadingAppointment || this.loadingOverview || this.loadingClientInfo;
            },
            title() {
                return this.lines.join(' ');
            },
            appointmentStatusId() {
                return !!this.appointment ? this.appointment.status.id : 0;
            },
            appointmentStatusColor() {
                return !!this.appointmentStatusId ? CalendarHelper.getAppointmentStatusColour(this.appointmentStatusId) : "#00BFFF";
            }
        },
        watch: {
            appoinmentDeletedEvent() {
                this.cancelClicked();
            },
            changeAppointmentInScheduleViewEvent(value) {
                this.cancelClicked();
                setTimeout(() => {
                    this.triggerViewAppointment(value);
                }, 500);
            }
        },
        methods: {
            ...mapMutations('ApplicationEvents', [
                'triggerSaveAppointment',
                'triggerDeleteAppointment',
                'triggerViewAppointment'
            ]),
            cancelClicked() {
                this.$refs.closeModal.click();
                this.error = '';
            }
        },
        components: {
            'modal-dialog': ModalDialog,
            'appointment-overview': AppointmentOverview,
            'client-info': AppointmentClientInfo,
            'error-banner': ErrorBanner,
            'delete-button': DeleteButton
        }
    }
</script>
