<template>
    <section class="mb-3">
        <div aria-expanded="false" class="filter-header" data-target="#mf-collapse-3" data-toggle="collapse">
            {{$t('Schedule')}}
            <div class="filter-value ml-auto"></div>
            <i class="far fa-chevron-down ml-3"></i>
        </div>
        <div id="mf-collapse-3" class="collapse">
            <div class="filter-group">
                <appointment-settings v-model="loadingAppointmentSettings"></appointment-settings>
                <staff-event-settings v-model="loadingStaffEventSettings"></staff-event-settings>
                <resource-settings v-model="loadingResourceSettings"></resource-settings>
                <site-settings v-model="loadingSiteSettings"></site-settings>
            </div>
        </div>
    </section>
</template>

<script>
    import CalendarAppointmentSettings from "./CalendarAppointmentSettings.vue";
    import CalendarResourceSettings from "./CalendarResourceSettings.vue";
    import CalendarStaffEventSettings from "./CalendarStaffEventSettings.vue";
    import ScheduleSiteSettings from "./ScheduleSiteSettings.vue";

    export default {
        name: 'ScheduleSettingsMobile',
        data() {
            return {
                loadingSiteSettings: false,
                loadingResourceSettings: false,
                loadingAppointmentSettings: false,
                loadingStaffEventSettings: false
            }
        },
        computed: {
            loading() {
                return this.loadingSiteSettings || this.loadingResourceSettings || this.loadingAppointmentSettings || this.loadingStaffEventSettings;
            }
        },
        watch: {
            loading(value) {
                this.$emit('input', value);
            }
        },
        components: {
            'site-settings': ScheduleSiteSettings,
            'appointment-settings': CalendarAppointmentSettings,
            'resource-settings': CalendarResourceSettings,
            'staff-event-settings': CalendarStaffEventSettings
        }
    }
</script>
