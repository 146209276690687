<template>
    <div class="form-group">
        <error-banner v-if="error" :message="error"></error-banner>
        <div v-if="addedTagType" class="alert alert-success alert-icon" role="alert">
            <div class="alert-content">
                <p>{{$t('Tag added successfully!')}}</p>
            </div>
        </div>
        <tag-name v-model="name" :validator="tagTypeAddValidator"></tag-name>
        <tag-colour v-model="colour" :validator="tagTypeAddValidator"></tag-colour>
        <tag-entity v-model="entityTypeId"></tag-entity>
        <div class="text-center">
            <button @click="tryAddTagType" v-bind:disabled="loadingTagTypes" class="btn btn-primary mr-2" type="button">
                {{$t('Add')}}
                <span v-if="loadingTagTypes" class="inline-loader"></span>
            </button>
            <button @click="onCancel" class="btn btn-outline-secondary">
                {{$t('Cancel')}}
            </button>
        </div>
        <div class="title-divider mt-4 mb-2">
            <h2>{{$t('All Tags')}}</h2>
        </div>
        <div class="d-flex flex-wrap">
            <tag-item v-for="tagType in tagTypes" :tag-type="tagType" :is-active="true" :tags="[]" :key="tagType.id" class="mr-1 mb-1">
                <template #active>
                    <i v-if="tagType.entityType.id === 2" class="far fa-user"></i>
                    <i v-else-if="tagType.entityType.id === 3" class="far fa-dog"></i>
                    <i v-else class="fas fa-asterisk"></i>
                </template>
                <template #inactive>
                    <i v-if="tagType.entityType.id === 2" class="far fa-user"></i>
                    <i v-else-if="tagType.entityType.id === 3" class="far fa-dog"></i>
                    <i v-else class="fas fa-asterisk"></i>
                </template>
            </tag-item>
        </div>
    </div>
</template>

<script>
    import { TagTypeAddMixin } from "../TagTypeAddMixin";
    import TagTypeNameInput from "./TagTypeNameInput.vue";
    import TagTypeColourInput from "./TagTypeColourInput.vue";
    import TagTypeEntitySelect from "./TagTypeEntitySelect.vue";
    import TagItem from "./TagItem.vue";
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';

    export default {
        name: 'TagTypeAdd',
        mixins: [TagTypeAddMixin],
        methods: {
            onCancel() {
                this.$emit('cancelled')
            }
        },
        computed: {
            sortedTagTypes() {
                return this.tagTypes.length > 0 
                    ? this.tagTypes.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
                    : [];
            }
        },
        components: {
            'tag-item': TagItem,
            'tag-name': TagTypeNameInput,
            'tag-colour': TagTypeColourInput,
            'tag-entity': TagTypeEntitySelect,
            'error-banner': ErrorBanner
        }
    }
</script>
