import moment from 'moment';
import { AppointmentScheduleHelper } from "../../helpers/AppointmentScheduleHelper";

const state = () => AppointmentScheduleHelper.getDefaultState(AppointmentScheduleHelper.getRoundedTime());

const getters = {
    indexAppointmentOrder: (state) => index => {
        return state.appointmentOrders[index];
    },
    additionalAppointmentOrders: (state) => {
        return state.appointmentOrders.slice(1);
    },
    indexOrderItem: (state) => (index, itemIndex) => {
        return state.appointmentOrders[index].orderItems[itemIndex];
    },
    scheduleAddModel: (state) => {
        return {
            clientId: state.clientId,
            startUtc: state.startUtc,
            duration: state.duration,
            appointmentFrequencyId: state.appointmentFrequencyId,
            frequencyMultiple: state.frequencyMultiple,
            occurrences: state.occurrences,
            participants: state.appointmentOrders.map(ao => ({
                petId: !!ao.pet ? ao.pet.id : '',
                resourceId: ao.resourceId
            })),
            overrides: state.overrides
        };
    },
    appointmentScheduleOrders: (state) => {
        return {
            appointmentOrders: state.appointmentOrders.map(ao => ({
                petId: !!ao.pet ? ao.pet.id : '',
                paymentMethodId: ao.paymentMethodId,
                orderItems: ao.orderItems
            }))
        };
    },
    scheduleAddModelValidation: (state) => {
        const v = {
            appointmentOrders: state.appointmentOrders.map(ao => ({
                petId: !!ao.pet ? '' : 'Please select pet',
                resourceId: !!ao.resourceId ? '' : 'Please select resource'
            }))
        }
        return Object.assign(v, {
            isValid: !v.appointmentOrders.some(ao => !!ao.petId || !!ao.resourceId)
        });
    },
    getOverrides: (state) => {
        return state.overrides;
    }
}

const mutations = {
    setDefault(state) {
        Object.assign(state, AppointmentScheduleHelper.getDefaultState(state.startUtc));
    },
    setStartDate(state, date) {
        const current = moment(state.startUtc);
        const next = moment(date);
        next.hours(current.hours());
        next.minutes(current.minutes());
        state.startUtc = next.utc().format();
    },
    setMainPet(state, pet) {
        state.appointmentOrders = [state.appointmentOrders[0]];
        state.appointmentOrders[0].pet = pet;
        state.clientId = !!pet ? pet.clientId : '';
        state.overrides = [];
    },
    setOrderItems(state, { index, items }) {
        state.appointmentOrders[index].orderItems = items;
    },
    removeOrderItem(state, { index, product }) {
        state.appointmentOrders[index].orderItems = state.appointmentOrders[index].orderItems
            .filter(oi => oi.productId !== product.id);
    },
    clearOrderItems(state, index) {
        state.appointmentOrders[index].orderItems = [];
    },
    setResource(state, { index, resource }) {
        state.appointmentOrders[index].resourceId = resource.id;
        state.appointmentOrders = [...state.appointmentOrders];
        state.overrides = [];
    },
    setStartUtc(state, moment) {
        state.startUtc = moment.utc().format();
        state.overrides = [];
    },
    setDuration(state, durationMinutes) {
        state.duration = parseInt(durationMinutes);
        state.overrides = [];
    },
    addAdditionalOrder(state) {
        state.appointmentOrders.push({
            pet: null,
            resourceId: '',
            paymentMethodId: state.appointmentOrders[0].paymentMethodId,
            orderItems: []
        });
    },
    removeAppointmentOrder(state, order) {
        state.appointmentOrders.splice(state.appointmentOrders.indexOf(order), 1);
    },
    setAddOns(state, addOns) {
        state.appointmentAddOns = [...addOns]
    },
    insertAddOn(state, addOn) {
        state.appointmentAddOns.push(addOn);
    },
    removeAddOn(state, value) {
        state.appointmentAddOns.splice(value.index, 1);
    },
    setDiscounts(state, discounts) {
        state.appointmentDiscounts = [...discounts]
    },
    insertDiscount(state, discount) {
        state.appointmentDiscounts.push(discount);
    },
    removeDiscount(state, value) {
        state.appointmentDiscounts.splice(value.index, 1);
    },
    setAdditionalPet(state, { index, pet }) {
        state.appointmentOrders[index].pet = pet;
        state.appointmentOrders = [...state.appointmentOrders];
        state.overrides = [];
    },
    setFrequency(state, frequencyId) {
        state.appointmentFrequencyId = parseInt(frequencyId);
        state.overrides = [];
    },
    setMultiple(state, multiple) {
        const value = parseInt(multiple);
        if (!isNaN(value)) {
            state.frequencyMultiple = value;
            state.overrides = [];
        }
    },
    setOccurrences(state, occurrences) {
        state.occurrences = parseInt(occurrences);
        state.overrides = [];
    },
    setNotes(state, notes) {
        state.notes = notes.toString();
    },
    setProductAmount(state, { index, itemIndex, amount }) {
        state.appointmentOrders[index].orderItems[itemIndex].amount = amount;
    },
    setProductQuantity(state, { index, itemIndex, quantity }) {
        state.appointmentOrders[index].orderItems[itemIndex].quantity = quantity;
    },
    setPaymentMethod(state, paymentMethodId) {
        const id = parseInt(paymentMethodId);
        for (var i = 0; i < state.appointmentOrders.length; i++) {
            state.appointmentOrders[i].paymentMethodId = id;
        }
    },
    setOverrides(state, overrides) {
        state.overrides = overrides;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations
}
