<template>
    <modal-dialog :title="$t(title)">
        <section class="rounded-card-white mb-3">
            <tag-type-add v-if="adding" @cancelled="adding=false"></tag-type-add>
            <tag-type-update v-else-if="updating" @cancelled="updating=false" :owner-id="ownerId" :owner-type-id="ownerTypeId"></tag-type-update>
            <template v-else>
                <div class="text-center">
                    <button @click="updating=true" class="btn btn-outline-secondary mr-2" type="button">
                        <i class="fas fa-edit"></i>
                        {{$t('Edit')}}
                    </button>
                    <button @click="adding=true" class="btn btn-primary" type="button">
                        <i class="fas fa-plus"></i>
                        {{$t('Create')}}
                    </button>
                </div>
                <div class="title-divider my-3">
                    <h2>{{$t(sectionTitle)}}</h2>
                </div>
                <tag-selector :tag-types="filteredTagTypes" :owner-id="ownerId" :owner-type-id="ownerTypeId"></tag-selector>
            </template>
        </section>
    </modal-dialog>
</template>

<script>
    import { mapState } from "vuex";
    import { TagTypesCacheMixin } from "../TagTypesCacheMixin";
    import TagSelector from "./TagSelector.vue";
    import TagTypeAdd from "./TagTypeAdd.vue"
    import TagTypeUpdate from "./TagTypeUpdate.vue"
    import ModalDialog from "../../Layout/ModalDialog.vue";

    export default {
        name: 'TagTypeList',
        mixins: [TagTypesCacheMixin],
        data() {
            return {
                adding: false,
                updating: false
            }
        },
        props: {
            ownerId: String,
            ownerTypeId: Number
        },
        computed: {
            filteredTagTypes() {
                return this.tagTypes
                    .filter(tt => !tt.entityType.id || tt.entityType.id === this.ownerTypeId)
                    .sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
            },
            title() {
                if (this.adding) {
                    return 'Create a Tag';
                } else if (this.updating) {
                    return 'Update a Tag';
                }

                switch (this.ownerTypeId) {
                    case 2:
                        return 'Client Tags';
                    case 3:
                        return 'Pet Tags';
                    default:
                        return 'Unknown Tags';
                }
            },
            sectionTitle() {
                return 'Assigned ' + this.title;
            }
        },
        components: {
            'modal-dialog': ModalDialog,
            'tag-selector': TagSelector,
            'tag-type-add': TagTypeAdd,
            'tag-type-update': TagTypeUpdate
        }
    }
</script>
