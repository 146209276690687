import { mapState, mapActions } from 'vuex';
import { ErrorHelper } from '../../helpers/ErrorHelper';
import { AddOnHelper } from '../../helpers/AddOnHelper'

export const AppointmentScheduleAddOnsAddMixin = {
    data() {
        return {
            error: '',
            addOnValidator: AddOnHelper.getAddOnValidator(),
            addOns: [],
        }
    },
    computed: {
        scheduleId() {
            console.log('!!!OVERRIDE COMPUTED - scheduleId!!!');
        },
        ...mapState('AppointmentScheduleUpload', [
            'appointmentAddOns'
        ]),
        appointmentAddOnsAddModel() {
            return {
                scheduleId: this.scheduleId,
                addOns: [...this.addOns]
            }
        },
        appointmentAddOnsAddModelValid() {
            const model = this.appointmentAddOnsAddModel;
            return !!model.scheduleId && model.addOns.length > 0;
        }
    },
    methods: {
        ...mapActions('AppointmentScheduleAddOns', [
            'addAppointmentScheduleAddOns'
        ]),
        tryAddAppointmentScheduleAddOns() {
            const self = this;
            self.validateAddOns();
            self.sanitizeAddOns();
            if (!self.appointmentAddOnsAddModelValid) {
                return;
            }
            self.error = '';
            return self.addAppointmentScheduleAddOns({ key: self.scheduleId, payload: self.appointmentAddOnsAddModel })
                .then(response => self.afterAddAppointmentScheduleAddOns(response.data))
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        afterAddAppointmentScheduleAddOns() {
            //override if necessary
        },
        validateAddOns() {
            const self = this;
            self.addOnValidator.check({
                scheduleId: self.scheduleId,
                addOns: [...self.appointmentAddOns]
            });
        },
        sanitizeAddOns() {
            const self = this;

            self.addOns = self.appointmentAddOns.filter(addOn => {
                const matched = self.addOnValidator.addOns.find(vao => vao.id === addOn.id);
                if (!matched) return true;

                const matchedInvalid = matched.amount || matched.quantity;
                return !matchedInvalid;
            });

            self.addOns.forEach(addOn => {
                if (!addOn.name) {
                    addOn.name = "Default";
                }
            });
        }
    }
}
