<template>
    <div class="search-wrapper" v-bind:class="{ 'show-search': searching }">
        <button class="btn-search">
            <i @click="searching = !searching" :class="searching ? 'far fa-times' : 'fas fa-search'"></i>
        </button>
        <div class="search-field-wrap">
            <div class="search-field">
                <input v-model="searchTerm" class="search-field-input form-control" :placeholder="$t('Search name, mobile, email, pet')" type="search" ref="searchTermField">
                <button class="search-field-btn" type="submit">
                    <span v-if="searchingPets" class="inline-loader"></span>
                    <template v-else>
                        <i class="fas fa-search"></i>
                    </template>
                </button>
                <div v-if="petResultsReady" class="inner show" role="listbox" id="bs-select-1" tabindex="-1">
                    <ul class="dropdown-menu inner show" role="presentation">
                        <template v-for="site in sitesWithPets">
                            <label class="group-header" v-if="displaySiteName">{{site.name}}</label>
                            <li v-for="pet in site.pets" :key="pet.id" class="m-0">
                                <div class="dropdown-item min d-flex flex-column">
                                    <pet-result :pet="pet"></pet-result>
                                    <div class="text-right">
                                        <button @click="onBookClick(pet)" class="btn btn-secondary mini greenColour btn-sm" title="New appointment"><i class="fa-duotone fa-calendar-check"></i></button>
                                        <button @click="onGotoClick(pet)" class="btn btn-secondary mini blueColour btn-sm" style="margin-left: 3px" title="View client"><i class="fa-solid fa-user"></i></button>
                                    </div>
                                </div>
                            </li>
                        </template>
                        <li v-if="sitesWithPets.length === 0">
                            <a role="option" class="dropdown-item">
                                {{$t('No results found')}}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import process from 'process';
    import { mapMutations } from 'vuex';
    import { ArrayHelper } from '../../helpers/ArrayHelper';
    import { PetClientsSearchMixin } from '../Pet/Search/PetClientsSearchMixin';
    import { PetBreedsSearchMixin } from "../Breeds/PetBreedsSearchMixin";
    import { PetSearchMixinV2 } from '../Shared/PetSearchMixinV2';
    import { SiteCacheMixin } from '../Shared/SiteCacheMixin';
    import router from '../../routing';
    import PetDisplayItem from '../Pet/Search/PetDisplayItem.vue';

    export default {
        name: 'ScheduleSearchPet',
        mixins: [PetSearchMixinV2, PetClientsSearchMixin, PetBreedsSearchMixin, SiteCacheMixin],
        data() {
            return {
                searching: false
            }
        },
        computed: {
            clientIds() {
                return this.pets.map(p => p.clientId);
            },
            breedIds() {
                return this.pets.map(p => p.breedId);
            },
            petsWithClients() {
                return this.pets.length > 0 ?
                    this.pets.filter(pet => {
                        const client = this.findClientCache(pet.clientId);
                        return client && client.value !== null;
                    }) : [];
            },
            sitesWithPets() {
                const petsGroupedBySite = this.petsWithClients.length > 0 ?
                    ArrayHelper.groupBy(this.petsWithClients, 'siteId') : [];
                const result = [];
                for (var i = 0; i < petsGroupedBySite.length; i++) {
                    const petGroup = petsGroupedBySite[i];
                    const site = this.sites.find(site => site.id === petGroup.key);
                    if (site) {
                        const siteWithPets = { ...site, pets: petGroup.values };
                        result.push(siteWithPets);
                    }
                }
                return result;
            },
            displaySiteName() {
                return this.sitesWithPets.length === 1 ? !this.sitesWithPets.some(swp => swp.id == this.siteId) : true;
            }
        },
        methods: {
            ...mapMutations('ApplicationEvents', [
                'triggerAddAppointmentWithDuration'
            ]),
            ...mapMutations('AppointmentScheduleUpload', [
                'setDefault',
                'setMainPet'
            ]),
            ...mapMutations('Sites', [
                'setSelected'
            ]),
            onBookClick(pet) {
                if (!!pet) {
                    this.setDefault();
                    this.searchTerm = '';
                    this.searching = false;
                    this.setSelected(pet.siteId);
                    this.setMainPet(pet);
                    this.triggerAddAppointmentWithDuration();
                }
            },
            onGotoClick(pet) {
                if (!!pet) {
                    router.push({ name: 'clientEdit', params: { id: pet.clientId } });
                }
            }
        },
        watch: {
            searching(value) {
                if (!!value) {
                    process.nextTick(() => this.$refs.searchTermField.focus());
                }
            }
        },
        components: {
            'pet-result': PetDisplayItem
        }
    }
</script>
